.grid002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $grey;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .grid002-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .grid002-heading {
      width: 100%;
      color: $colorAccent1;
      text-align: center;
      margin-bottom: 80px;

      strong {
        font-weight: 800;
      }

      @media (max-width: 1023px) {
        padding: 0 16px;
        margin-bottom: 40px;
      }
    }

    .grid002-services {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      .service-item {
        width: calc(25% - (90px / 4));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $light;
        box-shadow: 0px 8px 30px rgba(0, 64, 83, 0.1);
        border-radius: 30px;
        transition: transform ease-out .2s;
        padding: 8px;

        @media (max-width: 767px) {
          width: calc(50% - (24px / 2));
          margin-right: 24px;

          &:nth-child(2n+2) {
            margin-right: 0;
          }

          &:nth-child(1), &:nth-child(2) {
            margin-bottom: 24px;
          }
        }

        @media (max-width: 430px) {
          width: calc(50% - (16px / 2));
          margin-right: 16px;

          &:nth-child(1), &:nth-child(2) {
            margin-bottom: 16px;
          }
        }

        &:hover {
          transform: scale(1.05);
        }

        .service-image {
          width: 50%;
          max-width: 100%;
          height: auto;
          margin-bottom: 12px;
        }

        .service-name {
          @extend .titleBold;
          text-align: center;
        }

        //.service-description {
        //  width: 100%;
        //  max-width: 230px;
        //  text-align: center;
        //  color: $lightText;
        //  margin-bottom: 30px;
        //}
        //
        //.service-link {
        //  @extend .link-with-arrow;
        //}
      }
    }

    .grid002-button {
      display: inline-block;
      margin-top: 80px;
      @extend .button;
      @extend .outlined;

      @media (max-width: 1023px) {
        max-width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
  }
}
