.content001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .content001-container {
    @extend .container;
    display: flex;
    justify-content: space-between;

    .navigation-side {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      max-width: 305px;

      @media (max-width: 1023px) {
        display: none;
      }

      .navigation-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $grey;
        border-radius: 30px;
        padding: 40px;
        position: -webkit-sticky;
        position: sticky;
        top: 157px;

        @media (max-width: 1150px) {
          top: 115px;
        }

        .navigation-links {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-right: 30px;

          .navigation-link {
            @extend .caption;
            color: $lightText;
            margin-bottom: 25px;
            transition: all ease-out 0.2s;
            font-size: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            &.is-active {
              font-size: 16px;
              font-weight: 800;
              color: $colorAccent1;
            }
          }
        }

        .navigation-button {
          @extend .button;
          @extend .yellow;
          width: 100%;
          margin-top: 45px;

          .navigation-link {
            margin-top: 25px;
          }
        }
      }
    }

    .content-side {
      width: 100%;
      max-width: calc(100% - 355px);
      display: flex;
      flex-direction: column;

      @media (max-width: 1023px) {
        max-width: 100%;
      }

      .content-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;

        &:last-child {
          margin-bottom: 0;
        }

        .section-banner-container {
          width: 100%;
          display: flex;
          align-items: stretch;
          border-radius: 15px;
          overflow: hidden;
          background-color: $colorAccent2;
          margin: 70px 0;

          @media (max-width: 1023px) {
            margin: 20px 0;
          }

          @media (max-width: 767px) {
            flex-direction: column;
          }

          .banner-left-side {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 18px;

            @media (max-width: 767px) {
              width: 100%;
              padding: 40px 15px;
            }

            .banner-left-line {
              font-family: $fontNexa;
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 130%;
              text-align: center;
              color: $light;
              margin: 0;

              @media (max-width: 1200px) {
                font-size: 18px;
              }

              &:nth-child(2) {
                font-family: $fontNexa;
                font-style: normal;
                font-weight: 800;
                font-size: 48px;
                line-height: 120%;
                text-align: center;
                color: $colorAccent1;
                margin: 5px 0;

                @media (max-width: 1200px) {
                  font-size: 32px;
                }
              }
            }
          }

          .banner-right-side {
            position: relative;
            width: 50%;
            background-color: $colorAccent1;
            padding: 40px 25px 40px 75px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 767px) {
              width: 100%;
              padding: 80px 25px 35px;
            }

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: -14px;
              transform: translateY(-50%);
              width: 64px;
              height: 100px;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center left;
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCAxMDAiPjxwYXRoIGQ9Ik0xNCAwSDB2MTAwaDE0bDQ3Ljg3OS00Ny44NzlhMyAzIDAgMCAwIDAtNC4yNDJMMTQgMFoiIGZpbGw9IiMwMDQwNTMiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIzLjE1IDQ5LjkzNiA1LjExNiAzMS4xN2E0IDQgMCAwIDEgMC01LjU0M2wzLjI2NC0zLjM5OGE0IDQgMCAwIDEgNS43NjkgMEwzOC4xMSA0Ny4xNjVhNCA0IDAgMCAxIDAgNS41NDRMMTQuMTQ5IDc3LjY0NGE0IDQgMCAwIDEtNS43NjkgMGwtMy4yNjQtMy4zOThhNCA0IDAgMCAxIDAtNS41NDNMMjMuMTUgNDkuOTM2WiIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");

              @media (max-width: 767px) {
                top: -32px;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
              }
            }

            .banner-button {
              @extend .button;
              @extend .yellow;
            }
          }
        }

        .section-name {
          @extend .caption;
          color: $lightText;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .section-heading {
          @extend h3;
          margin-bottom: 30px;

          strong {
            font-weight: 800;
          }

          h1 {
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            line-height: 120%;
            margin: 0;

            &.bold, strong {
              font-weight: 800;
            }

            @media (max-width: 767px) {
              font-size: 32px;
            }
          }

          h2 { @extend h4; }

          h3 { @extend h5; }
        }

        .content-container {
          width: 100%;
          display: flex;
          flex-direction: column;

          & > * {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .content-text {
            color: $lightText;

            strong {
              font-weight: 800;
            }

            a:not(.button) {
              color: $colorAccent1;
              text-decoration: underline;
            }

            h1 {
              font-style: normal;
              font-weight: normal;
              font-size: 48px;
              line-height: 120%;
              margin: 0;

              &.bold, strong {
                font-weight: 800;
              }

              @media (max-width: 767px) {
                font-size: 32px;
              }
            }

            h2 { @extend h4; }

            h3 { @extend h5; }

            .button {
              display: inline-block;
              margin-top: 16px;
            }
          }

          .content-image {
            border-radius: 30px;
            overflow: hidden;
            width: 100%;
            height: 100%;
          }

          .content-video {
            position: relative;
            width: 100%;
            display: flex;
            border-radius: 30px;
            overflow: hidden;

            & > img {
              position: relative;
              width: 100%;
              height: 100%;
            }

            .video-info {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.49) 0%, rgba(0, 0, 0, 0.11) 100%), linear-gradient(360deg, #000000 -37.91%, rgba(0, 0, 0, 0.991353) -33.1%, rgba(0, 0, 0, 0.96449) -28.29%, rgba(0, 0, 0, 0.91834) -23.48%, rgba(0, 0, 0, 0.852589) -18.66%, rgba(0, 0, 0, 0.768225) -13.85%, rgba(0, 0, 0, 0.668116) -9.04%, rgba(0, 0, 0, 0.557309) -4.23%, rgba(0, 0, 0, 0.442691) 0.58%, rgba(0, 0, 0, 0.331884) 5.4%, rgba(0, 0, 0, 0.231775) 10.21%, rgba(0, 0, 0, 0.147411) 15.02%, rgba(0, 0, 0, 0.0816599) 19.83%, rgba(0, 0, 0, 0.03551) 24.65%, rgba(0, 0, 0, 0.0086472) 29.46%, rgba(0, 0, 0, 0) 34.27%);

              .video-info-svg {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                & > svg {
                  height: 58px;
                }
              }

              .video-name {
                position: absolute;
                left: 24px;
                bottom: 30px;
                width: calc(100% - 48px);
                color: $grey;
                font-weight: 800;
                text-align: left;
              }
            }
          }

          .content-accordion {
            width: 100%;
            display: flex;
            flex-direction: column;

            .accordion-item {
              width: 100%;
              display: flex;
              flex-direction: column;
              border-radius: 15px;
              background-color: $grey;
              padding: 24px 30px;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              &.is-active {
                .accordion-text {
                  margin-top: 10px;
                  opacity: 1;
                }

                .accordion-heading:after {
                  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAzIj48cGF0aCBkPSJNMTQuMzA2LjgwM2gxMi40MzFhLjYzLjYzIDAgMDEuNjI1LjYzOS42MzQuNjM0IDAgMDEtLjYyNS42MzhILjYyNUEuNjM0LjYzNCAwIDAxMCAxLjQ0MkMwIDEuMDkuMjgxLjgwMy42MjUuODAzaDEzLjY4MXoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=");
                }
              }

              .accordion-heading {
                position: relative;
                @extend .titleBold;
                padding-right: 60px;
                cursor: pointer;

                &:after {
                  content: '';
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 27px;
                  height: 27px;
                  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyOCI+PHBhdGggZD0iTTI3LjA1NiAxMy4zNzVoLTEyLjQzVi45NDRBLjYyNy42MjcgMCAwMDE0IC4zMTlhLjYyNy42MjcgMCAwMC0uNjI1LjYyNXYxMi40MzFILjk0NEEuNjI3LjYyNyAwIDAwLjMxOSAxNGMwIC4zNDQuMjgxLjYyNS42MjUuNjI1aDEyLjQzMXYxMi40MzFjMCAuMzQ0LjI4MS42MjUuNjI1LjYyNWEuNjI3LjYyNyAwIDAwLjYyNS0uNjI1di0xMi40M2gxMi40MzFhLjYyNy42MjcgMCAwMC42MjUtLjYyNi42MjMuNjIzIDAgMDAtLjYyNS0uNjI1eiIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");
                  background-size: 27px 27px;
                  background-repeat: no-repeat;
                  background-position: center center;
                }

                h1 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 48px;
                  line-height: 120%;
                  margin: 0;

                  &.bold, strong {
                    font-weight: 800;
                  }

                  @media (max-width: 767px) {
                    font-size: 32px;
                  }
                }

                h2 { @extend h4; }

                h3 { @extend h5; }
              }

              .accordion-text {
                color: $lightText;
                margin-top: 0;
                max-height: 0;
                overflow: hidden;
                will-change: max-height;
                transition: all 0.25s ease-out;
                opacity: 0;

                figure {
                  margin: 0 0 30px;

                  img {
                    max-width: 100%;
                  }
                }

                strong {
                  font-weight: 800;
                }

                a {
                  color: $colorAccent1;
                  text-decoration: underline;
                }

                h1 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 48px;
                  line-height: 120%;
                  margin: 0;

                  &.bold, strong {
                    font-weight: 800;
                  }

                  @media (max-width: 767px) {
                    font-size: 32px;
                  }
                }

                h2 { @extend h4; }

                h3 { @extend h5; }
              }
            }
          }

          .clinic-list {
            width: 100%;
            display: flex;
            flex-direction: column;

            .clinic-item.is-hidden {
              display: none;
            }

            .clinic-list-more-link {
              align-self: center;
              @extend .caption;
              transition: all ease-out 0.2s;
              font-size: 16px;
              font-weight: 800;
              color: $dark;

              &.is-hidden {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
