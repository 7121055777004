.team001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 100px 0;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .team001-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .team001-heading {
      @extend h3;
      margin-bottom: 40px;
    }

    .team001-text {
      color: $lightText;
      margin-bottom: 80px;
      max-width: 685px
    }

    .slider-intro {
      margin-bottom: 50px;
      font-weight: 700;

      @media (max-width: 767px) {
        margin-bottom: 80px;
      }
    }

    .slider-container {
      position: relative;
      width: 100%;
      display: flex;

      .flickity-viewport {
        width: 100%;
        overflow: visible;
      }

      .flickity-prev-next-button {
        position: absolute;
        top: -88px;
        right: 0;
        left: initial;
        width: 44px;
        height: 44px;
        border-radius: 44px;
        background-color: $colorAccent2;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS42MTQgMy41aDguMDg1TDYuMjEuN2wuNjIyLS43IDMuNTU0IDQtMy41NTQgNC0uNjIyLS43TDguNyA0LjVILjYxNHYtMXoiIGZpbGw9IiNGNUY5RkMiLz48L3N2Zz4=");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        transform: none;

        &.previous {
          right: 60px;
          transform: rotate(180deg);
        }

        &:disabled {
          opacity: 0.3;
        }

        &:before, svg {
          display: none;
        }
      }

      .member-item {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0px 8px 30px rgba(0, 64, 83, 0.1);
        border-radius: 30px;
        padding: 16px 24px 24px;

        @media (max-width: 1023px) {
          width: calc((100% - 30px) / 2);
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        .member-infos {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .member-photo {
            width: 66px;
            border-radius: 66px;
            filter: drop-shadow(0px 8px 30px rgba(0, 64, 83, 0.1));
          }

          .member-tags {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .member-tag {
              @extend .caption;
              font-weight: 800;
              color: $light;
              background-color: $colorAccent1;
              padding: 4px 8px;
              border-radius: 16px;

              &:first-child {
                margin-bottom: 8px;
              }
            }
          }
        }

        .member-name {
          @extend .titleBold;
          margin-bottom: 8px;
        }

        .member-bio {
          color: $lightText;
        }
      }
    }
  }
}
