.blog001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .blog001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blog001-filters {
      width: 100%;
      display: flex;

      .blog001-filters-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-bwtween;
        background: $grey;
        border-radius: 50px;
        margin-bottom: 100px;

        @media (max-width: 1023px) {
          display: none;
        }

        .blog001-filter {
          position: relative;
          flex: 1;
          color: $lightText;
          font-weight: 800;
          text-align: center;
          padding: 10px;
          cursor: pointer;
          transition: color ease-out 0.2s;
          border-radius: 50px;

          &.is-active {
            color: $light;
            background-color: $colorAccent2;
          }
        }
      }

      .blog001-filter-form {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 100px;

        @media (max-width: 1023px) {
          display: flex;
        }

        @media (max-width: 767px) {
          margin-bottom: 24px;
        }

        .blog001-filter-label {
          font-weight: 800;
          color: $lightText;
          margin-bottom: 8px;
        }
      }
    }

    .blog001-listing {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 70px;

      @media (max-width: 767px) {
        margin-bottom: 0;
      }

      .blog-item {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .blog-image-container {
          border-radius: 30px;
          margin-bottom: 35px;
          @extend .effect-zoom-image;

          @media (max-width: 767px) {
            border-radius: 16px;
            width: 110px;
            height: 110px;
            margin-bottom: 0;
          }

          .blog-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .blog-infos {
          width: 100%;
          display: flex;
          flex-direction: column;

          @media (max-width: 767px) {
            width: calc(100% - 126px);
          }
        }

        .blog-category {
          @extend .subtitle;
          color: $lightText;
          margin-bottom: 8px;
        }

        .blog-title {
          @extend .titleBold;
          margin-bottom: 10px;
        }

        .blog-short {
          color: $lightText;

          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .blog001-button {
      @extend .button;
      @extend .outlined;
    }
  }
}
