// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  @extend .regularText;
  color: $dark;
}

.fonts-loaded body {
  font-family: $fontNexa;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Helvetica, Arial, sans-serif;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6, {
  font-family: $fontNexa;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}

.hide-for-screen {
  font-size: 0;
}

/* -- For screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 1px solid #5db7a5; /* Adjust to suit your tastes */
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

/* -- Fontello */

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.responsive-img {
  width: 100%;
  height: 100%;
}

.flickity-prev-next-button {
  background: #fff;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.flickity-prev-next-button svg {
  display: none;
}

.flickity-prev-next-button.previous {
  left: 0;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.previous {
    background-color: $colorAccent1;
    width: 32px;
    height: 32px;
    left: 15px;
  }
}

.flickity-prev-next-button.previous:before {
  content: "\E827";
  position: relative;
  top: -1px;
  font-family: fontello;
  font-size: 28px;
  color: black;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.previous:before {
    top: 0;
    font-size: 19px;
    color: #fff;
  }
}

.flickity-prev-next-button.next {
  right: 0;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.next {
    background-color: $colorAccent1;
    width: 32px;
    height: 32px;
    right: 15px;
  }
}

.flickity-prev-next-button.next:before {
  content: "\E826";
  position: relative;
  top: -1px;
  font-family: fontello;
  font-size: 28px;
  color: black;
}

@media (max-width: 1024px) {
  .flickity-prev-next-button.next:before {
    top:0;
    font-size: 19px;
    color: #fff;
  }
}

::-moz-selection { background: $colorAccent1; color: #fff; }
::selection { background: $colorAccent1; color: #fff; }

[v-cloak] {
  display: none;
}

.site-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &:not(.pains) {
    @media (max-width: 767px) {
      overflow-x: hidden;
    }
  }

  .main {
    flex: 1;
    margin-top: 107px;

    &.top {
      margin-top: 0;
    }

    @media (max-width: 1150px) {
      margin-top: 75px;
    }

    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }

  &.homepage {
    .main {
      margin-top: 0;
    }
  }
}

.container {
  width: 100%;
  max-width: calc(1140px + (2 * 30px));
  padding: 0 30px;

  @media (max-width: 767px) {
    max-width: calc(1140px + (2 * 16px));
    padding: 0 16px;
  }
}

.not-desktop {
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
}

.site-opener {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99995;
  pointer-events: none;


  .layer-dark1, .layer-light, .layer-dark2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $colorAccent2;
    will-change: transform;
    pointer-events: none;
    animation: opener-animation .2s forwards 3.25s;
  }

  .layer-light {
    background-color: $colorAccent1;
    animation: opener-animation .2s forwards 3.1s;
  }

  .layer-dark2 {
    animation: opener-animation .2s forwards 2.8s;
  }

  .svg-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes opener-animation {
    from {transform: translateY(0);}
    to {transform: translateY(100%);}
  }
}

.effect-zoom-image {
  overflow: hidden;

  & > img {
    transition: transform ease-out 1s;
  }

  &:hover {
    & > img {
      transform: scale(1.1);
    }
  }
}

//@media (max-width: 1165px) {
//  #fb-root iframe {
//    left: 22px !important;
//    right: initial !important;
//    animation: none !important;
//  }
//}


.mobile-appointment-button {
  position: fixed;
  z-index: 10;
  left: 16px;
  bottom: 24px;
  display: none;
  height: 54px;
  border-radius: 54px;
  background-color: $colorAccent3;
  padding: 11px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 64, 83, 0.3);

  @media (max-width: 1165px) {
    display: flex;
  }

  svg {
    height: 32px;
    transition: height ease-out .2s;
  }

  span {
    width: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 170%;
    color: $dark;
    white-space: nowrap;
    transition: all ease-out .2s;
    opacity: 0;
  }

  &.is-expanded {
    svg {
      height: 20px;
    }

    span {
      width: auto;
      margin-left: 8px;
      opacity: 1;
    }
  }
}

.flickity-page-dots {
  bottom: 0;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: $lightText;
    margin: 0 4px;
    transition: all ease-out .2s;

    &.is-selected {
      width: 25px;
      background-color: $colorAccent1;
    }
  }
}

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $colorAccent1;
  color: #fff;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}
