.search001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .search001-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .search-back-link {
      @extend .link-with-arrow;
      @extend .is-reversed;
      margin-bottom: 30px;
    }

    .search-heading {
      margin-bottom: 24px;

      & > span {
        color: $colorAccent1;
        font-weight: 800;
      }
    }

    .pagination-info {
      color: $lightText;
      text-transform: uppercase;
      margin-bottom: 60px;
    }

    .search-results {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #D6D8E7;

      .result-item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 40px 0;
        border-bottom: 1px solid #D6D8E7;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        .result-info {
          flex: 1;
          max-width: 750px;
          padding-right: 80px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 767px) {
            padding-right: 0;
            margin-bottom: 10px;
          }

          .result-title {
            @extend .titleBold;
            margin-bottom: 12px;
          }

          .result-desc {
            color: $lightText;
          }
        }

        .result-button {
          @extend .button;
          @extend .outlined;
        }
      }
    }

    .results-pagination {
      margin-top: 100px;
    }
  }
}
