$mobileBreak: 900px;

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100vw;
  width: 100vw;
  transition: all cubic-bezier(.76,0,.24,1) 0.8s;
  background-color: $light;
  pointer-events: none;
  z-index: 20;

  &.is-active {
    transform: translateX(100vw);
    pointer-events: auto;

    .menu-wrapper .image-container .image-container-mask {
      transform: translate(0);
      width: 100%;
      opacity: 1;
    }

    .menu-wrapper .appointment-button {
      opacity: 1;
    }

    .menu-wrapper .menu-container .menu-first-level .menu-links .menu-link {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .menu-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    background-color: $colorAccent2;

    @media (max-width: $mobileBreak) {
      padding: 30px 24px 120px;
    }

    .menu-container {
      position: relative;
      min-width: 50%;
      height: 100vh;
      display: flex;
      padding: 0 40px 0 130px;

      @media (max-width: 1100px) {
        padding: 0 40px 0;
      }

      @media (max-width: $mobileBreak) {
        padding: 0;
        width: 100vw;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .menu-first-level {
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 90px 0;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        @media (max-width: $mobileBreak) {
          height: auto;
          width: 100%;
          padding: 0;
          overflow-y: auto;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        @media (min-width: $mobileBreak + 1) {
          &.is-hovered {
            .menu-links {
              .menu-link {
                & > span {
                  opacity: 0.2;
                }

                &.is-hovered > span {
                  opacity: 1;
                }
              }
            }
          }

          &.is-selected {
            .menu-links {
              .menu-link {
                & > span {
                  opacity: 0.2;
                }

                &.is-selected > span {
                  opacity: 1;
                }
              }
            }
          }
        }

        .search-container {
          width: calc(100% - 60px);
          max-width: 320px;
          margin-bottom: 30px;
          display: none;

          @media (max-width: 520px) {
            max-width: 100%;
          }

          & > input {
            @extend .titleBold;
            width: 100%;
            padding: 0 50px 8px 0;
            appearance: none;
            outline: none;
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid $light;
            background-color: transparent;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiAxNC45MjhMMTQuOTI4IDE2bC00LjM5OS00LjRhNi41IDYuNSAwIDExMS4wNzItMS4wNzJMMTYgMTQuOTN6bS05LjQ4NS0zLjQxM2E1IDUgMCAxMDAtMTAgNSA1IDAgMDAwIDEweiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
            background-repeat: no-repeat;
            background-size: auto 16px;
            background-position: right 16px top 4px;
            color: $light;

            &::placeholder {
              color: rgba($light, 0.4);
            }
          }

          @media (max-width: $mobileBreak) {
            display: block;
          }
        }

        .mobile-links {
          width: 100%;
          display: none;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 30px;

          @media (max-width: $mobileBreak) {
            display: flex;
          }

          .mobile-link {
            font-weight: 700;
            color: $light;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            &.mobile-only {
              display: none;

              @media (max-width: $mobileBreak) {
                display: block;
              }
            }
          }
        }

        .menu-links {
          width: 100%;
          max-width: 310px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: $mobileBreak) {
            max-width: 100%;
          }

          .menu-link {
            position: relative;
            @extend h3;
            font-size: 38px;
            font-weight: 800;
            color: $light;
            padding-bottom: 45px;
            opacity: 0;
            transform: translateX(10vw);
            transition: all cubic-bezier(.76,0,.24,1) 0.8s;
            cursor: pointer;

            @media (max-width: $mobileBreak) {
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 27px;
              padding-bottom: 16px;
            }

            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                transition-delay: $i * 0.05s + 0.4s;
              }
              //&:nth-child(#{$i}):before {
              //  transition-delay: $i * 0.05s + 0.4s;
              //}
            }

            &:last-child {
              padding-bottom: 0;

              &:before {
                top: calc(50% - 8px);
              }
            }

            &:before {
              content: '';
              position: absolute;
              top: calc(50% - 30px);
              transform: translateX(-50px);
              z-index: 2;
              left: 0;
              width: 16px;
              height: 16px;
              border-radius: 16px;
              background-color: $light;
              opacity: 0;
              pointer-events: none;
              transition: all cubic-bezier(.76,0,.24,1) 0.4s;
            }

            &.is-selected, &.is-selected:before {
              transition-delay: 0s !important;
            }

            & > span {
              transition: opacity ease-out 0.2s;
              pointer-events: none;
            }

            @media (min-width: $mobileBreak + 1) {
              &.is-selected {
                padding-left: 30px;

                &:before {
                  opacity: 1;
                  transform: translateX(0);
                }
              }
            }

            @media (max-width: $mobileBreak) {
              &.has-children:after {
                display: inline-block;
                content: '';
                margin-left: 10px;
                width: 14px;
                height: 14px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA5Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMzA1IDUuMzQ4YTEgMSAwIDAwMS4zOSAwbDQuNC00LjI1YS44OTIuODkyIDAgMTExLjI0IDEuMjg0TDcuNjk1IDcuODNhMSAxIDAgMDEtMS4zOSAwTC42NjUgMi4zODJBLjg5Mi44OTIgMCAwMTEuOTA1IDEuMWw0LjQgNC4yNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }

      .menu-second-level {
        position: relative;
        height: 100vh;
        transform: translateX(-50%);
        opacity: 0;
        width: 20px;
        transition: width cubic-bezier(.76,0,.24,1) 0.6s, transform cubic-bezier(.76,0,.24,1) 0.6s, opacity cubic-bezier(.76,0,.24,1) 0.6s;
        pointer-events: none;

        @media (max-width: $mobileBreak) {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow-y: scroll;
          width: 100%;
          height: 100%;
          background-color: $colorAccent2;
          transform: translateX(100%);
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &.is-active {
          opacity: 1;
          transform: translateX(0);
          width: 345px;
          pointer-events: auto;

          @media (max-width: $mobileBreak) {
            width: 100%;
          }
        }

        @media (min-width: $mobileBreak + 1) {
          &.is-hovered {
            .menu-sublinks {
              .menu-sublink {
                & > span {
                  opacity: 0.2;
                }

                &.is-hovered > span {
                  opacity: 1;
                }
              }
            }
          }

          &.is-selected {
            .menu-sublinks {
              .menu-sublink {
                & > span {
                  opacity: 0.2;
                }

                &.is-selected > span {
                  opacity: 1;
                }
              }
            }
          }
        }

        .back-link {
          display: none;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          margin-bottom: 40px;

          & > svg {
            width: 22px;
          }

          @media (max-width: $mobileBreak) {
            display: flex;
          }
        }

        .menu-sublinks {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
          padding: 105px 0 90px 30px;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;

          @media (max-width: $mobileBreak) {
            padding: 0;
            position: relative;
            top: initial;
            right: initial;
            bottom: initial;
            left: initial;
            z-index: initial;
            overflow-y: auto;
            display: none;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          &.is-active {
            z-index: 1;

            @media (max-width: $mobileBreak) {
              display: block;
            }

            .menu-sublinks-container .menu-sublink {
              opacity: 1;
              transform: translateX(0);
              transition: all cubic-bezier(.76,0,.24,1) 0.8s;

              @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                  transition-delay: $i * 0.05s + 0.2s;
                }
              }
            }
          }

          .menu-sublinks-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .menu-sublink {
              @extend h4;
              color: $light;
              padding-bottom: 45px;
              opacity: 0;
              transform: translateX(10vw);

              @media (max-width: $mobileBreak) {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                padding-bottom: 16px;
              }

              &:last-child {
                padding-bottom: 0;
              }

              &.is-not-hover > span {
                opacity: 0.2;
              }

              & > span {
                transition: opacity ease-out 0.2s;
                pointer-events: none;
              }
            }
          }
        }
      }

      .menu-third-level {
        position: relative;
        height: 100vh;
        transform: translateX(-50%);
        opacity: 0;
        width: 20px;
        transition: width cubic-bezier(.76,0,.24,1) 0.6s, transform cubic-bezier(.76,0,.24,1) 0.6s, opacity cubic-bezier(.76,0,.24,1) 0.6s;
        pointer-events: none;

        @media (max-width: $mobileBreak) {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow-y: scroll;
          width: 100%;
          height: 100%;
          background-color: $colorAccent2;
          transform: translateX(100%);
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &.is-active {
          opacity: 1;
          transform: translateX(0);
          width: 300px;
          pointer-events: auto;

          @media (max-width: $mobileBreak) {
            width: 100%;
          }
        }

        @media (min-width: $mobileBreak + 1) {
          &.is-hovered {
            .menu-sublinks {
              .menu-sublink {
                & > span {
                  opacity: 0.2;
                }

                &.is-hovered > span {
                  opacity: 1;
                }
              }
            }
          }

          &.is-selected {
            .menu-sublinks {
              .menu-sublink {
                & > span {
                  opacity: 0.2;
                }

                &.is-selected > span {
                  opacity: 1;
                }
              }
            }
          }
        }

        .back-link {
          display: none;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          margin-bottom: 40px;

          & > svg {
            width: 22px;
          }

          @media (max-width: $mobileBreak) {
            display: flex;
          }
        }

        .menu-sublinks {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          padding: 105px 0 90px 30px;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;
          pointer-events: none;

          &.is-active {
            pointer-events: auto;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          @media (max-width: $mobileBreak) {
            padding: 0;
            position: relative;
            top: initial;
            right: initial;
            bottom: initial;
            left: initial;
            z-index: initial;
            overflow-y: auto;
            display: none;
          }

          &.is-active {
            @media (max-width: $mobileBreak) {
              display: block;
            }

            .menu-sublinks-container .menu-sublink {
              opacity: 1;
              transform: translateX(0);
              transition: all cubic-bezier(.76,0,.24,1) 0.8s;

              @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                  transition-delay: $i * 0.05s + 0.2s;
                }
              }
            }
          }

          .menu-sublinks-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .menu-sublink {
              @extend h5;
              color: $light;
              padding-bottom: 55px;
              opacity: 0;
              transform: translateX(10vw);

              @media (max-width: $mobileBreak) {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                padding-bottom: 24px;
              }

              &:last-child {
                padding-bottom: 0;
              }

              &.is-not-hover > span {
                opacity: 0.2;
              }

              & > span {
                transition: opacity ease-out 0.2s;
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .image-container {
      flex-grow: 1;
      position: relative;

      @media (max-width: $mobileBreak) {
        display: none;
      }

      .image-container-mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        z-index: 21;
        transform: translate(-20vw);
        opacity: 0;
        transition: all cubic-bezier(.76,0,.24,1) .8s;
        transition-delay: .4s;

        .menu-image-item {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 0;
          overflow: hidden;

          &.is-active {
            transition: width .4s cubic-bezier(.76,0,.24,1);
            width: 100%;
          }

          &.is-previous-active {
            width: 100%;
          }

          .menu-image {
            position: absolute;
            top: 0;
            height: 100%;
            object-fit: cover;
            object-position: 50% 0;
            left: 0;
            width: 100%;
            min-width: 50vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .appointment-button {
      position: absolute;
      top: 24px;
      right: 30px;
      @extend .button;
      @extend .small;
      z-index: 30;
      opacity: 0;
      transition: all ease-out 0.2s;

      @media (max-width: $mobileBreak) {
        top: initial;
        right: initial;
        left: 24px;
        bottom: 32px;
      }

      @media (max-width: 520px) {
        width: calc(100% - 48px);
      }
    }

    .close-menu {
      position: absolute;
      top: 24px;
      left: 24px;
      width: 44px;
      height: 44px;
      border-radius: 44px;
      background-color: $light;
      cursor: pointer;

      @media (max-width: $mobileBreak) {
        left: initial;
        right: 24px;
      }

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 17px;
      }
    }
  }
}
