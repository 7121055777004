.stepsOfCare {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $grey;

  @media (max-width: 1023px) {
    padding: 0 0 80px;
    background-color: $light;

    &.for-landing {
      padding: 80px 0;
      background-color: $grey;

      .stepsOfCare-container .stepsOfCare-steps .stepsOfCare-step {
        background-color: $light;
      }
    }
  }

  .stepsOfCare-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1023px) {
      align-items: flex-start;
    }

    .stepsOfCare-heading-container {
      @extend h3;
      width: 100%;
      max-width: 475px;
      display: flex;
      flex-direction: column;
      align-content: center;

      @media (max-width: 1023px) {
        align-content: flex-start;
        max-width: 100%;
      }

      .stepsOfCare-suptitle {
        width: 100%;
        @extend .caption;
        color: $lightText;
        text-transform: uppercase;
        margin-bottom: 15px;
        text-align: center;

        @media (max-width: 1023px) {
          text-align: left;
        }
      }

      .stepsOfCare-heading {
        width: 100%;
        text-align: center;
        margin-bottom: 80px;

        strong {
          font-weight: 800;
        }

        @media (max-width: 1023px) {
          //@extend h3;
          margin-bottom: 30px;
          text-align: left;

          strong {
            font-weight: 800;
          }
        }
      }
    }

    .stepsOfCare-steps {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .stepsOfCare-step {
        width: calc((100% - 150px) / 4);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1023px) {
          width: 100%;
          border-radius: 15px;
          background-color: $grey;
          padding: 24px 30px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.is-active {
          .step-content {
            margin-top: 10px;
            opacity: 1;
          }

          .step-header:after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAzIj48cGF0aCBkPSJNMTQuMzA2LjgwM2gxMi40MzFhLjYzLjYzIDAgMDEuNjI1LjYzOS42MzQuNjM0IDAgMDEtLjYyNS42MzhILjYyNUEuNjM0LjYzNCAwIDAxMCAxLjQ0MkMwIDEuMDkuMjgxLjgwMy42MjUuODAzaDEzLjY4MXoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=");
          }
        }

        .step-header {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 16px;

          @media (max-width: 1023px) {
            position: relative;
            margin-bottom: 0;
            flex-direction: row;
            cursor: pointer;

            &:after {
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 27px;
              height: 27px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyOCI+PHBhdGggZD0iTTI3LjA1NiAxMy4zNzVoLTEyLjQzVi45NDRBLjYyNy42MjcgMCAwMDE0IC4zMTlhLjYyNy42MjcgMCAwMC0uNjI1LjYyNXYxMi40MzFILjk0NEEuNjI3LjYyNyAwIDAwLjMxOSAxNGMwIC4zNDQuMjgxLjYyNS42MjUuNjI1aDEyLjQzMXYxMi40MzFjMCAuMzQ0LjI4MS42MjUuNjI1LjYyNWEuNjI3LjYyNyAwIDAwLjYyNS0uNjI1di0xMi40M2gxMi40MzFhLjYyNy42MjcgMCAwMC42MjUtLjYyNi42MjMuNjIzIDAgMDAtLjYyNS0uNjI1eiIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");
              background-size: 27px 27px;
              background-repeat: no-repeat;
              background-position: center center;
              transition: background-image ease-out .2s;
            }
          }

          .step-icon-container {
            width: 100%;
            max-width: 160px;

            @media (max-width: 1023px) {
              max-width: 32px;
            }

            .step-icon-wrapper {
              position: relative;
              width: 100%;
              padding-top: 100%;
              border-radius: 50%;
              background-color: $light;
              margin-bottom: 24px;

              @media (max-width: 1023px) {
                display: flex;
                padding-top: 0;
                border-radius: 0;
                background: none;
                margin-bottom: 0;
              }

              .step-icon-number {
                position: absolute;
                left: 50%;
                top: -20px;
                transform: translateX(-50%);
                @extend h2;
                font-weight: 700;
                color: $colorAccent1;
                text-shadow: 4px 4px 0 #fff, 4px -4px 0 #fff, -4px 4px 0 #fff, -4px -4px 0 #fff, 4px 0px 0 #fff, 0px 4px 0 #fff, -4px 0px 0 #fff, 0px -4px 0 #fff;

                @media (max-width: 1023px) {
                  display: none;
                }
              }

              .step-icon {
                position: absolute;
                left: 50%;
                bottom: 20%;
                transform: translateX(-50%);
                width: 75px;
                height: 75px;
                object-fit: contain;
                object-position: center center;

                @media (max-width: 1023px) {
                  position: relative;
                  left: initial;
                  bottom: initial;
                  transform: none;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .step-heading {
            @extend .titleBold;
            text-align: center;

            @media (max-width: 1023px) {
              text-align: left;
              color: $colorAccent1;
              margin-left: 12px;
              width: calc(100% - 80px);
            }

            span {
              display: none;

              @media (max-width: 1023px) {
                display: inline-block;
              }
            }
          }
        }

        .step-content {
          text-align: center;

          @media (max-width: 1023px) {
            text-align: left;
            margin-top: 0;
            max-height: 0;
            overflow: hidden;
            will-change: max-height;
            transition: all 0.25s ease-out;
            opacity: 0;
          }
        }
      }
    }
  }
}
