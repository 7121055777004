.syndroms001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  overflow-x: hidden;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .syndroms001-container {
    @extend .container;
    position: relative;
    z-index: 9;

    .syndroms001-heading {
      margin-bottom: 24px;
    }

    .syndroms001-filters {
      padding-top: 45px;
      padding-bottom: 17px;
      border-bottom: 1px solid #D6D8E7;
      position: sticky;
      top: 110px;
      z-index: 10;
      background-color: #fff;

      @media (max-width: 1150px) {
        top: 75px;
        padding-bottom: 0;
        padding-top: 24px;
      }

      @media (max-width: 767px) {
        //margin-right: -16px;
        //padding-right: 16px;
      }

      .syndroms001-filters-wrapper {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 767px) {
          flex-wrap: nowrap;
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

      }

      .syndroms001-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        margin-bottom: 24px;
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: $colorAccent1;


        border: 1px solid #D6D8E7;
        border-radius: 15px;

        @media (max-width: 1023px) {
          width: 30px;
          min-width: 30px;
          height: 30px;
          border-radius: 5px;
          font-size: 16px;
          line-height: 1;
        }

        @media (max-width: 767px) {
          &:last-child {
            margin-right: 16px;
          }
        }


        &:hover {
          background: #00BCE7;
          color: $light;
        }

        &.is-active {
          background: #00BCE7;
          color: $light;
        }
      }
    }

    .syndroms001-list {
      padding-top: 48px;
      padding-bottom: 0;
      z-index: 8;

      .syndroms001-list-heading {
        margin-top: 48px;
        margin-bottom: 16px;
        color: $colorAccent1;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.syndrom-link {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 16px 50px 16px 16px;
  background-color: $grey;
  margin-bottom: 16px;
  border: 2px solid $grey;
  padding-right: 45px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translatey(-50%);
    display: flex;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
    background-position: center left 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-position ease-out .2s;
  }

  &:hover {
    &:after {
      animation: arrow-right 1s ease-out infinite;
    }
  }

  span {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 170%;
    color: #293F52;
  }
}
