.content002 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .content002-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .content-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 150px;

      @media (max-width: 1023px) {
        margin-bottom: 80px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .section-heading {
        margin-bottom: 30px;

        p {
          margin-bottom: 30px;
        }

        strong {
          font-weight: 800;
        }

        a {
          color: $colorAccent1;
        }

        figure {
          margin: 0 0 30px;
          text-align: center;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}
