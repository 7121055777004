.faq001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .faq001-container {
    position: relative;
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .faq001-heading {
      width: 100%;
      max-width: 730px;
      color: $dark;
      text-align: center;
      margin: 0 0 70px;

      @media (max-width: 1023px) {
        margin-bottom: 40px;
      }

      strong {
        font-weight: 800;
      }
    }

    .faq001-filters-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;

      @media (max-width: 767px) {
        margin-bottom: 24px;
      }

      .faq001-filters-desktop {
        width: 100%;
        display: flex;
        background: $grey;
        border-radius: 45px;

        @media (max-width: 767px) {
          display: none;
        }

        .filters-item {
          flex: 1;
          display: flex;
          height: 45px;
          align-items: center;
          justify-content: center;
          font-family: $fontNexa;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 170%;
          text-align: center;
          color: $lightText;
          margin: 0;
          cursor: pointer;

          &.is-active {
            background-color: $colorAccent2;
            border-radius: 45px;
            color: $light;
          }
        }
      }

      .faq001-filters-mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }

        .filters-label {
          font-family: $fontNexa;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 170%;
          color: $lightText;
          margin: 0 0 8px;
        }
      }
    }

    .content-accordion {
      width: 100%;
      display: flex;
      flex-direction: column;

      .accordion-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        background-color: $grey;
        padding: 24px 30px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        &.is-hidden {
          display: none;
        }

        &.is-active {
          .accordion-text {
            margin-top: 10px;
            opacity: 1;
          }

          .accordion-heading:after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAzIj48cGF0aCBkPSJNMTQuMzA2LjgwM2gxMi40MzFhLjYzLjYzIDAgMDEuNjI1LjYzOS42MzQuNjM0IDAgMDEtLjYyNS42MzhILjYyNUEuNjM0LjYzNCAwIDAxMCAxLjQ0MkMwIDEuMDkuMjgxLjgwMy42MjUuODAzaDEzLjY4MXoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=");
          }
        }

        .accordion-heading {
          position: relative;
          @extend .titleBold;
          padding-right: 60px;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 27px;
            height: 27px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyOCI+PHBhdGggZD0iTTI3LjA1NiAxMy4zNzVoLTEyLjQzVi45NDRBLjYyNy42MjcgMCAwMDE0IC4zMTlhLjYyNy42MjcgMCAwMC0uNjI1LjYyNXYxMi40MzFILjk0NEEuNjI3LjYyNyAwIDAwLjMxOSAxNGMwIC4zNDQuMjgxLjYyNS42MjUuNjI1aDEyLjQzMXYxMi40MzFjMCAuMzQ0LjI4MS42MjUuNjI1LjYyNWEuNjI3LjYyNyAwIDAwLjYyNS0uNjI1di0xMi40M2gxMi40MzFhLjYyNy42MjcgMCAwMC42MjUtLjYyNi42MjMuNjIzIDAgMDAtLjYyNS0uNjI1eiIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");
            background-size: 27px 27px;
            background-repeat: no-repeat;
            background-position: center center;
            transition: background-image ease-out .2s;
          }
        }

        .accordion-text {
          color: $lightText;
          margin-top: 0;
          max-height: 0;
          overflow: hidden;
          will-change: max-height;
          transition: all 0.25s ease-out;
          opacity: 0;
        }
      }
    }
  }
}
