.blog002 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .blog002-image {
    height: 100%;
    max-height: 400px;
  }

  .blog002-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;

    .blog-category {
      @extend .caption;
      color: $lightText;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .blog-title {
      margin-bottom: 8px;
    }

    .blog-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid #F4F8FF;
      margin-bottom: 40px;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .blog-date {
        color: $lightText;

        @media (max-width: 1023px) {
          margin-bottom: 16px;
        }
      }

      .page-subnav {
        display: flex;
        align-items: center;

        .nav-link {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }

          svg {
            width: 30px;
          }
        }
      }
    }

    .blog-content {
      width: 100%;

      p {
        margin-bottom: 30px;
      }

      h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 120%;
        margin: 0;

        &.bold, strong {
          font-weight: 800;
        }

        @media (max-width: 767px) {
          font-size: 32px;
        }
      }

      h2 { @extend h4; }

      h3 { @extend h5; }

      strong {
        font-weight: 800;
      }

      a {
        color: $colorAccent1;
      }

      figure {
        margin: 0 0 30px;
        text-align: center;

        img {
          max-width: 100%;
        }
      }
    }
  }
}
