.filter001 {
  display: none;
  
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 32px;
  }

  .filter-container {
    @extend .container;

    .filter-title {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 170%;
      color: #73879A;
      margin: 0;
      margin-bottom: 2px;
    }

    .filter-select {
      display: block;
      width: 100%;
      background-color: #F4F8FF;
      padding: 19px 24px;
      border: none;
      border-color: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      line-height: 170%;
      color: #0D313F;
      border-radius: 15px;
    }







  }
}
