.logos001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  .logos001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logos001-heading {
      @extend h3;
      margin-bottom: 60px;
      text-align: center;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .logos {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .logo-container {
        width: calc((100% - 300px) / 4);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 100px;

        &:nth-child(4n+4) {
          margin-right: 0;
        }

        @media (max-width: 1023px) {
          width: calc((100% - 90px) / 4);
          margin-right: 30px;
        }

        @media (max-width: 767px) {
          width: calc((100% - 60px) / 3);
          margin-right: 30px;

          &:nth-child(4n+4) {
            margin-right: 30px;
          }

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }

        img {
          width: 100%;
          max-width: 200px;
          height: auto;
        }
      }
    }
  }
}
