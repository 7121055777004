.hero003 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background-color: $grey;

  @media (max-width: 1150px) {
    min-height: 450px;
  }

  &.light {
    background-color: $light;
  }

  &.dark {
    background-color: $colorAccent2;

    .hero003-heading,
    .hero003-text {
      color: $light;
    }
  }

  &.creations {
    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }

    .hero003-container {
      .content-side {
        .hero003-text {
          color: $light;
        }
      }

      .hero003-button {
        @media (max-width: 1023px) {
          display: none;
        }
      }
    }

    .image-side {
      @media (max-width: 1023px) {
        position: relative;
        display: block;
        width: 100%;
        height: 350px;
      }
    }
  }

  .hero003-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (max-width: 1023px) {
      align-items: center;
    }

    .content-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        align-items: center;
      }

      .hero003-heading {
        width: calc(50% - 140px);
        @extend h3;

        @media (max-width: 1150px) {
          width: calc(50% - 40px);
        }

        @media (max-width: 1023px) {
          width: 100%;
          text-align: center;
        }

        strong {
          font-weight: 800;
        }
      }

      .hero003-text {
        width: calc(50% - 140px);
        margin-top: 30px;
        color: $lightText;

        @media (max-width: 1150px) {
          width: calc(50% - 40px);
        }

        @media (max-width: 1023px) {
          width: 100%;
          text-align: center;
        }
      }

      .hero003-button {
        @extend .button;
        @extend .yellow;
        margin-top: 32px;
      }
    }
  }

  .image-side {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    overflow: hidden;

    @media (max-width: 1023px) {
      display: none;
    }

    .image-side-content {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      transition: transform ease-out 1s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}
