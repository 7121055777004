.values001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  .values001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .values001-heading {
      @extend h3;
      margin-bottom: 60px;
      text-align: center;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .values {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .value-item {
        width: calc((100% - 120px) / 5);
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(5n+5) {
          margin-right: 0;
        }

        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 40px;
          flex-direction: row;
          align-items: center;
          margin-right: 0;

          &:nth-child {
            margin-bottom: 0;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
        }

        .value-icon {
          width: 100%;
          max-width: 110px;
          height: auto;
          margin-bottom: 50px;

          @media (max-width: 1023px) {
            margin-bottom: 0;
          }

          @media (max-width: 767px) {
            margin-bottom: 20px;
            max-width: 75px;
          }
        }

        .value-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 1023px) {
            align-items: flex-start;
            padding-left: 30px;
          }

          @media (max-width: 767px) {
            padding-left: 0;
            align-items: center;
          }

          .value-name {
            @extend .titleBold;
            text-align: center;
            margin-bottom: 16px;

            @media (max-width: 1023px) {
              text-align: left;
            }

            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .value-description {
            text-align: center;
            color: $lightText;

            @media (max-width: 1023px) {
              text-align: left;
            }

            @media (max-width: 767px) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
