$mobileBreak: 799px;

.testimonials001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;

  .testimonials001-container {
    position: relative;
    @extend .container;
    display: flex;
    align-items: stretch;

    @media (max-width: $mobileBreak) {
      flex-direction: column;
      align-items: center;
    }

    .heading-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 120px 0;

      @media (max-width: $mobileBreak) {
        width: 100%;
        padding: 80px 0 40px;
      }

      .testimonials-big-heading {
        font-weight: 900;
        font-size: 140px;
        color: $light;
        margin: 5px 0;

        @media (max-width: 1200px) {
          font-size: 80px;
          text-align: center;
        }
      }

      .testimonials-small-heading {
        @extend h3;
        color: $light;
        margin: 5px 0;

        strong {
          font-weight: 800;
        }

        @media (max-width: 1200px) {
          text-align: center;
        }
      }

      .testimonials-small-text {
        color: $light;
        margin: 5px 0;

        @media (max-width: 1200px) {
          text-align: center;
        }
      }
    }

    .testimonials-side {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 180px 0 180px 70px;

      @media (max-width: $mobileBreak) {
        width: 100%;
        padding: 0 0 80px;
        align-items: center;
        position: initial;
      }

      .testimonials-slider {
        position: relative;
        width: 100%;

        .testimonial-item {
          width: 100%;

          @media (max-width: $mobileBreak) {
            margin-bottom: 40px;
          }

          .testimonial-text {
            color: $light;
            margin-bottom: 35px;

            @media (max-width: 1200px) {
              text-align: center;
            }
          }

          .author {
            width: 100%;
            display: flex;
            align-items: center;

            @media (max-width: 520px) {
              flex-direction: column;
              align-items: center;
            }

            .author-photo {
              width: 56px;
              display: flex;

              picture,
              img {
                width: 100%;
                height: 100%;
              }
            }

            .author-infos {
              width: calc(100% - 56px);
              padding-left: 15px;
              display: flex;
              flex-direction: column;
              padding-right: 100px;

              @media (max-width: 520px) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-top: 5px;
                align-items: center;
              }

              .author-name {
                @extend .titleBold;
                color: $light;
              }

              .author-description {
                color: $light;
              }
            }
          }
        }

        .slider-pagination {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 3;

          @media (max-width: $mobileBreak) {
            right: 50%;
            transform: translateX(50%);
          }

          .pagination-container {
            display: flex;
            align-items: center;

            .prev-button {
              padding: 2px 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              svg {
                height: 14px;
                transform: rotate(180deg);
              }
            }

            .next-button {
              padding: 2px 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              svg {
                height: 14px;
              }
            }

            .pagination-text {
              color: $colorAccent1;
              margin-bottom: -2px;
            }
          }
        }
      }

      .top-curve {
        position: absolute;
        top: -18px;
        left: -60px;
        width: 320px;

        @media (max-width: $mobileBreak) {
          width: 360px;
          left: -180px;
        }

        & svg {
          width: 100%;
        }
      }

      .bottom-curve {
        position: absolute;
        bottom: -18px;
        right: 0;
        width: 320px;
        transform: rotate(180deg);

        @media (max-width: $mobileBreak) {
          width: 360px;
          right: -180px;
          bottom: -20px;
        }

        & svg {
          width: 100%;
        }
      }
    }
  }
}
