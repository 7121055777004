.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .main-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $colorAccent2;
    padding: 50px 0;

    @media (max-width: 550px) {
      padding: 90px 0;
    }

    .main-footer-container {
      @extend .container;
      display: flex;
      flex-direction: column;

      .top-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        @media (max-width: 550px) {
          flex-direction: column;
          margin-bottom: 0;
        }

        .logo {
          display: flex;

          & > svg {
            height: 50px;
            max-width: 100%;
          }
        }

        .social-links {
          display: flex;
          align-items: center;

          @media (max-width: 550px) {
            margin: 40px 0;
          }

          .nav-link {
            position: relative;
            width: 42px;
            height: 42px;
            border-radius: 42px;
            background-color: $light;
            margin-right: 10px;
            transition: all ease-out 0.2s;
            // Yellow
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI0ZGQ0IwNyIvPjwvc3ZnPg==");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 0;

            &:hover {
              background-size: 100%;
            }

            &:last-child {
              margin-right: 0;
            }

            & > svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 17px;
            }
          }
        }
      }

      .main-line {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @media (max-width: 550px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .footer-infos {
          display: flex;
          flex-direction: column;

          @media (max-width: 550px) {
            align-items: center;
            width: 100%;
            padding: 30px 0;
            border-top: 1px solid rgba(#8298AB, 0.35);
            border-bottom: 1px solid rgba(#8298AB, 0.35);
          }

          .info-link {
            display: flex;
            align-items: center;
            color: $light;

            & > div {
              width: 22px;
              display: flex;
              justify-content: center;
              margin-right: 10px;

              & > svg {
                height: 17px;
              }
            }
          }

          .info-button {
            @extend .button;
            margin-top: 32px;

            @media (max-width: 550px) {
              display: none;
            }
          }
        }

        .footer-links {
          display: flex;
          align-items: flex-start;

          @media (max-width: 950px) {
            display: none;
          }

          .links-column {
            display: flex;
            flex-direction: column;

            &:first-child {
              margin-right: 40px;
            }

            .footer-link {
              color: $light;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .footer-newsletter {
          display: flex;
          flex-direction: column;

          @media (max-width: 550px) {
            width: 100%;
            margin-top: 40px;
            align-items: center;
          }

          .newsletter-label {
            color: $light;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: center;
          }

          .info-button {
            @extend .button;
          }

          .input-container {
            width: 100%;
            max-width: 230px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            background-color: rgba(#E1E9F0, 0.4);
            border-radius: 50px;
            overflow: hidden;

            .newsletter-input {
              flex: 1;
              appearance: none;
              outline: none;
              border: none;
              @extend .caption;
              padding: 15px 0 15px 15px;
              color: $light;
              background-color: transparent;
            }

            button[type="submit"] {
              width: 55px;
              background-color: transparent;
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy45MDguMDYzYS4yODMuMjgzIDAgMDAtLjI5LS4wNEwuMTU2IDcuNjQ4QS4yNTMuMjUzIDAgMDAwIDcuODc0YS4yNTIuMjUyIDAgMDAuMTUuMjNMMy42IDkuNTY1Yy4wOS4wNDIgNy4yLTMuNDc4IDcuMi0zLjQ3OEw2LjQzNyAxMC42OGEuNS41IDAgMDAtLjEzNy4zNDR2My40MDZhLjUuNSAwIDAwLjg1Ny4zNWwyLjI4NC0yLjMyNyAzLjYwMSAxLjcxM2MuMDcuMDMxLjE1Mi4wMzMuMjIzLjAwM2EuMjU5LjI1OSAwIDAwLjE1LS4xNTZMMTcuOTg4LjMzYy4wMzItLjA5NiAwLS4yLS4wOC0uMjY2eiIgZmlsbD0iI0Y1RjlGQyIvPjwvc3ZnPg==");
              background-size: 18px 15px;
              background-repeat: no-repeat;
              appearance: none;
              outline: none;
              background-position: top 15px right 20px;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .dark-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $dark;

    .dark-footer-container {
      @extend .container;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 100px;
      }

      & > p {
        @extend .caption;
        color: $light;

        @media (max-width: 550px) {
          margin-bottom: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
