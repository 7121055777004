.arguments003 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .arguments003-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .arguments003-heading {
      @extend h3;
      width: 100%;
      text-align: center;
      margin-bottom: 65px;
    }

    .arguments-list {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .argument-item {
        width: calc((100% - 180px) / 3);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1023px) {
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 60px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        .entity-logo {
          width: 100%;
          max-width: 200px;
          margin-bottom: 30px;

          img {
            width: 100%;
          }
        }

        .entity-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 1023px) {
            align-items: flex-start;
            padding-left: 30px;
          }

          @media (max-width: 767px) {
            align-items: center;
            padding-left: 0;
          }

          .entity-name {
            @extend .titleBold;
            margin-bottom: 16px;
            text-align: center;

            @media (max-width: 1023px) {
              text-align: left;
            }

            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .entity-description {
            color: $lightText;
            text-align: center;

            @media (max-width: 1023px) {
              text-align: left;
            }

            @media (max-width: 767px) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
