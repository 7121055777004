.creations-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: background-color ease-out .2s;

  &.is-top {
    background-color: $colorAccent2;
  }

  .navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

    @media (max-width: 1165px) {
      width: 100%;
      padding: 20px 16px;
      min-height: 75px;
    }

    @media (max-width: 767px) {
      padding: 10px 16px;
      min-height: 50px;
    }

    .logo {
      display: flex;
      margin-right: 40px;

      @media (max-width: 1250px) {
        margin-right: 20px;
      }

      & > svg {
        height: 30px;

        @media (max-width: 1165px) {
          height: 26px;
        }
      }
    }

    .navigation-button {
      @extend .button;
      @extend .yellow;

      @media (max-width: 520px) {
        width: auto;
      }
    }
  }
}
