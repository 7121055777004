.career002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .career002-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1023px) {
      align-items: center;
    }

    .career002-heading {
      @extend h3;
      margin-bottom: 50px;

      @media (max-width: 1023px) {
        text-align: center;
      }
    }

    .career002-filters {
      width: 100%;
      display: flex;
      margin-bottom: 50px;

      @media (max-width: 1023px) {
        justify-content: center;
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .career002-filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 24px;

        @media (max-width: 1023px) {
          align-items: center;
        }

        @media (max-width: 767px) {
          width: 100%;
          align-items: flex-start;
        }

        &:last-child {
          margin-right: 0;

          @media (max-width: 767px) {
            margin-top: 20px;
          }
        }

        .career002-label {
          font-weight: 800;
          color: $lightText;
          margin-bottom: 8px;
        }

        .career002-select {
          @extend .form-select;
        }
      }
    }

    .career002-list {
      width: 100%;

      .job-list-line {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D6D8E7;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid #EFF0F7;
          box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
          border-radius: 9px;
          border-bottom: none;
          padding: 16px;
          margin-bottom: 16px;

          &:first-child {
            display: none;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:hover {
          .link-with-arrow:after {
            animation: arrow-right 1s ease-out infinite;
          }
        }

        .list-heading {
          font-weight: 800;
          padding-bottom: 16px;

          @media (max-width: 767px) {
            display: none;
          }
        }

        .job-info {
          position: relative;
          color: $lightText;
          padding: 24px 0;

          @media (max-width: 767px) {
            flex-direction: column;
            padding: 0;
            width: 100%;

            &:first-child {
              font-weight: 800;
              margin-bottom: 8px;
            }

            &:nth-child(2) {
              margin-bottom: 16px;
            }

            &:nth-child(3) {
              padding-right: 32px;
            }
          }
        }

        .flex1 {
          flex: 3;
        }

        .flex2 {
          flex: 2;
        }

        .flex3 {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 767px) {
            position: absolute;
            right: 16px;
            bottom: 16px;
            width: auto;
          }
        }
      }

      a {
        display: flex;

        svg {
          width: 24px;
        }
      }
    }

    .career002-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 100px;

      @media (max-width: 1023px) {
        margin-top: 50px;
      }
    }
  }
}
