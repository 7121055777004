.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: transform ease-out 0.2s;
  pointer-events: auto;

  &.is-hidden {
    pointer-events: none;
    transform: translateY(-100%);
  }

  .light-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light;
    padding: 10px 30px;

    @media (max-width: 1165px) {
      display: none;
    }

    .social-links {
      display: flex;
      align-items: center;

      .nav-link {
        display: flex;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        & > svg {
          height: 17px;
        }
      }
    }

    .search-container {
      display: flex;
      align-items: center;

      .language-link {
        font-weight: 700;
        margin-right: 30px;
      }

      & > svg {
        height: 16px;
        cursor: pointer;
      }

      & > input {
        width: 120px;
        appearance: none;
        outline: none;
        border: none;
        margin-left: 16px;
        padding: 5px;
        transition: all ease-out 0.2s;

        &:focus {
          width: 200px;
        }
      }
    }
  }

  .main-bar {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: $colorAccent2;

    .left-section {
      display: flex;
      align-items: center;
      padding: 20px 30px;

      @media (max-width: 1165px) {
        width: 100%;
        justify-content: space-between;
        padding: 20px 16px;
        min-height: 75px;
      }

      @media (max-width: 767px) {
        padding: 10px 16px;
        min-height: 50px;
      }

      .burger-menu {
        display: none;

        & > svg {
          height: 16px;
        }

        @media (max-width: 1165px) {
          display: block;
        }
      }

      .appointment-icon {
        display: none;

        & > svg {
          height: 26px;
        }

        @media (max-width: 1165px) {
          display: block;
        }
      }

      .logo {
        display: flex;
        margin-right: 40px;

        @media (max-width: 1250px) {
          margin-right: 20px;
        }

        & > svg {
          height: 30px;

          @media (max-width: 1165px) {
            height: 26px;
          }
        }
      }

      .main-nav {
        display: flex;
        align-items: center;

        @media (max-width: 1165px) {
          display: none;
        }

        .nav-link {
          display: flex;
          align-items: center;
          color: $light;
          margin-right: 40px;

          @media (max-width: 1250px) {
            margin-right: 20px;
          }

          &:last-child {
            margin-right: 0;
          }

          &.has-children:after {
            display: inline-block;
            content: '';
            margin-left: 10px;
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA5Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMzA1IDUuMzQ4YTEgMSAwIDAwMS4zOSAwbDQuNC00LjI1YS44OTIuODkyIDAgMTExLjI0IDEuMjg0TDcuNjk1IDcuODNhMSAxIDAgMDEtMS4zOSAwTC42NjUgMi4zODJBLjg5Mi44OTIgMCAwMTEuOTA1IDEuMWw0LjQgNC4yNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .right-section {
      display: flex;
      align-items: stretch;

      @media (max-width: 1165px) {
        display: none;
      }

      .dark-button {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: $light;
        padding: 20px;
        background-color: $dark;
        transition: all ease-out 0.2s;
        // White
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 0;

        &:hover {
          background-size: 120%;
          color: $dark;

          & > svg > path {
            fill: $dark;
          }
        }

        & > svg {
          height: 16px;
          margin-right: 10px;

          & > path {
            transition: fill ease-out .2s;
          }
        }
      }

      .light-button {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: $dark;
        padding: 20px;
        background-color: $colorAccent3;
        transition: all ease-out 0.2s;
        // White
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 0;

        &:hover {
          background-size: 120%;
          color: $dark;
        }
      }
    }
  }
}
