.custom-alert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000001;
  background: #0000008D;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-out .2s;

  &.is-open {
    pointer-events: auto;
    opacity: 1;

    .custom-alert-wrapper {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .custom-alert-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .custom-alert-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 50px));
    width: calc(100% - 64px);
    max-width: 774px;
    max-height: calc(100vh - 64px);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.07), 0 0 29.2013px rgba(0, 0, 0, 0.0482987), 0 0 14.1767px rgba(0, 0, 0, 0.0389404), 0 0 6.94968px rgba(0, 0, 0, 0.0310596), 0 0 2.74791px rgba(0, 0, 0, 0.0217013);
    border-radius: 25px;
    background: #FFFFFF;
    opacity: 0;
    border: 1px solid #707070;
    transition: opacity ease-out .2s, transform ease-out .2s;
    transition-delay: .2s;
    overflow-y: auto;

    .custom-alert-container {
      position: relative;
      width: 100%;
      display: flex;
      align-items: stretch;

      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .custom-alert-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 70px 70px 40px 70px;

        @media (max-width: 767px) {
          padding: 30px 24px;
        }

        &.left-align {
          .custom-alert-title,
          .custom-alert-text {
            text-align: left;
          }
        }

        .custom-alert-title {
          color: $colorAccent1;
          @extend .titleBold;
          margin-bottom: 24px;
          font-size: 28px;

          @media (max-width: 576px) {
            font-size: 24px;
          }
        }

        .custom-alert-text {
          color: #000000;
          @extend .titleBold;
          text-align: center;

          &:has(strong) {
            font-weight: normal;
          }

          strong {
            font-weight: 800;
          }

          ul {
            text-align: left;
          }
        }

        .custom-alert-button {
          margin-top: 40px;
          @extend .button;
          min-width: 136px;
          font-size: 26px;
        }
      }
    }
  }
}
