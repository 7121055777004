// Titles
h1, h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 105%;
  margin: 0;

  &.bold, strong {
    font-weight: 800;
  }

  @media (max-width: 1100px) {
    font-size: 54px;
  }

  @media (max-width: 830px) {
    font-size: 32px;
    line-height: 120%;
  }
}

h3, .h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 120%;
  margin: 0;

  &.bold, strong {
    font-weight: 800;
  }

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

h4, .h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 120%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

// Texts
.titleBold {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.regularText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}

.caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 115%;
  margin: 0;

  &.bold {
    font-weight: 700;
  }
}
