$mobileBreak: 885px;

.hero001 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $colorAccent1;
  overflow: hidden;

  @media (max-width: $mobileBreak) {
    padding-bottom: 160px;
  }

  .hero-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 30px;

    @media (max-width: $mobileBreak) {
      padding: 24px 16px;
    }

    @media (max-width: 767px) {
      padding: 12px 16px;
    }

    .burger-menu {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      & > svg {
        height: 24px;

        @media (max-width: $mobileBreak) {
          height: 20px;
        }
      }
    }

    .hero-logo {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & > svg {
        height: 40px;

        @media (max-width: $mobileBreak) {
          height: 26px;
        }
      }
    }

    .hero-cta {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .appointment-button {
        @extend .button;
        @extend .small;

        @media (max-width: $mobileBreak) {
          display: none;
        }
      }

      .mobile-appointment {
        display: none;

        & > svg {
          height: 28px;
        }

        //@media (max-width: $mobileBreak) {
        //  display: flex;
        //}
      }
    }
  }

  .hero-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 20px;

    @media (max-width: $mobileBreak) {
      flex-direction: column-reverse;
      padding: 0 20px 20px;
    }

    .hero-socials {
      width: 20%;
      display: flex;
      align-items: center;

      @media (max-width: $mobileBreak) {
        display: none;
      }

      .nav-link {
        display: flex;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        & > svg {
          height: 24px;

          path {
            fill: $light;
          }
        }
      }

    }

    .hero-arrow {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobileBreak) {
        display: none;
      }

      .arrow-link {
        padding: 10px;
        cursor: pointer;
        transition: transform ease-out .2s;

        &:hover {
          transform: translateY(10px);
        }

        svg {
          width: 33px;
        }
      }
    }

    .hero-fb-chat {
      width: 20%;

      @media (max-width: $mobileBreak) {
        display: none;
      }
    }
  }

  .hero-button-wrapper {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    @media (max-width: $mobileBreak) {
      width: 100%;
      margin: 20px 0 0;
    }

    .hero-button {
      position: absolute;
      top: 0;
      left: 50%;
      @extend .button;
      @extend .outlined;
      @extend .light;
      transform: translateX(calc(-50% + 150px));
      opacity: 0;
      pointer-events: none;
      transition: all ease-out .4s;
      width: 390px;

      @media (max-width: $mobileBreak) {
        font-size: 12px;
      }

      @media (max-width: 520px) {
        width: 290px;
      }

      &.is-active {
        pointer-events: auto;
        opacity: 1;
        transform: translateX(-50%);
        border-color: $light;
        color: $light;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
      }

      &:hover {
        color: $dark;
      }

      &.is-passed {
        transform: translateX(calc(-50% - 150px));
        opacity: 0;
      }
    }
  }

  .hero-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0 0;

    @media(max-width: $mobileBreak) {
      flex: 0;
      margin: 10px 0 0;
    }

    .hero-container {
      position: relative;
      flex: 1;
      display: flex;
      width: 100%;
      max-height: 446px;

      @media(max-width: $mobileBreak) {
        flex-direction: column;
        align-items: center;
        max-height: none;
      }

      .hero-ellipse {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;

        @media (max-width: $mobileBreak) {
          position: relative;
          height: 400px;
          order: 2;
        }

        @media(max-width: 767px) {
          height: 216px;
        }

        svg {
          height: 100%;
          width: 100%;
        }
      }

      .hero-content {
        position: absolute;
        width: 100%;
        max-width: 344px;
        top: -16px;
        right: calc(50% - 30px);
        padding: 16px 20px 24px 16px;
        background-color: $colorAccent1;

        @media screen and (min-width: 768px) and (max-height: 700px) {
          right: 50%;
          max-width: 280px;
        }

        @media screen and (max-width: 1280px) {
          right: 50%;
          max-width: 280px;
        }

        @media(max-width: $mobileBreak) {
          position: relative;
          order: 1;
          top: initial;
          right: initial;
          max-width: 640px;
          text-align: center;
          width: 100%;
          background: none;
          padding: 0;
          margin-bottom: 40px;
        }

        @media(max-width: 767px) {
          max-width: 265px;
        }

        .hero-heading {
          color: $light;
          font-style: normal;
          font-weight: normal;
          font-size: 38px;
          line-height: 120%;
          text-transform: uppercase;
          margin: 0;

          @media screen and (min-width: 768px) and (max-height: 700px) {
            font-size: 28px;
          }

          @media screen and (max-width: 1280px) {
            font-size: 28px;
          }

          @media(max-width: $mobileBreak) {
            font-size: 41.2636px;
          }

          @media(max-width: 767px) {
            font-size: 30px;
          }
        }
      }

      .swiper-container {
        position: absolute;
        top: 50%;
        height: 115%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        overflow: visible;

        @media(max-width: $mobileBreak) {
          top: inherit;
          bottom: -15px;
          transform: none;
          height: 460px;
        }

        @media(max-width: 767px) {
          height: 248.5px;
          bottom: -16.2px
        }

        @keyframes float {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(20px);
          }
          100% {
            transform: translatey(0px);
          }
        }

        .swiper-slide {
          position: relative;
          display: flex;
          max-height: 100%;
          align-items: center;
          justify-content: center;
          transform: scale(.3);
          transition: transform ease-out .2s;
          cursor: pointer;

          &.swiper-slide-prev {
            transform: scale(.6) translateX(-50%);
          }

          &.swiper-slide-next {
            transform: scale(.6) translateX(50%);
          }

          &.swiper-slide-active {
            transform: scale(1.3);

            img {
              animation: float 6s ease-in-out infinite;
            }

            &:after {
              transform: translateY(-40px);

              @media(max-width: $mobileBreak) {
                transform: translateY(-40px) translateX(-50%);
              }
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -80px;
            width: 100%;
            height: 23%;
            background: url('/img/site/home-slider-shadow.png') no-repeat center center / contain;
            transition: transform ease-out 0.2s;

            @media(max-width: $mobileBreak) {
              left: 50%;
              bottom: -60px;
              transform: translateX(-50%);
              width: 200px;
            }
          }

          .slide-image {
            width: 100%;
            height: auto;

            &.desktop {
              @media(max-width: $mobileBreak) {
                display: none;
              }
            }

            &.mobile {
              display: none;
              @media(max-width: $mobileBreak) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
