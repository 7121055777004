.map002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  .map002-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .map002-heading {
      @extend h3;
      margin-bottom: 60px;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .map002-link {
      @extend .link-with-arrow;
      @extend .is-reversed;
      margin-bottom: 24px;
    }

    .map-list-container {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      @media (max-width: 1023px) {
        flex-direction: column;
        height: auto;
      }

      .info-container {
        width: calc(70% - 15px);
        min-height: 525px;
        background: #FCFCFC;
        border: 1px solid #EFF0F7;
        box-sizing: border-box;
        box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
        border-radius: 15px;
        padding: 40px;
        display: flex;

        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 24px;
          min-height: auto;
        }

        @media (max-width: 767px) {
          padding: 16px;
          flex-direction: column;
        }

        .info-column {
          width: 50%;
          border-right: 1px solid #D6D8E7;
          padding-right: 30px;

          @media (max-width: 767px) {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #D6D8E7;
            padding-right: 0;
            padding-bottom: 24px;
            margin-bottom: 24px;
          }

          &:last-child {
            padding-right: 0;
            padding-left: 30px;
            border-right: none;

            @media (max-width: 767px) {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 0;
              padding-left: 0;
            }
          }

          .clinic-name {
            @extend .titleBold;
            margin-bottom: 16px;
          }

          .clinic-info {
            display: none;
            position: relative;
            font-weight: 800;
            margin-bottom: 16px;
            padding-left: 16px;
            color: $colorSuccess;

            &.is-active {
              display: block;
            }

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 8px;
              height: 8px;
              border-radius: 8px;
              background-color: $colorSuccess;
            }
          }

          .clinic-button {
            @extend .button;
            @extend .hover-blue;
            @extend .yellow;
            margin-bottom: 32px;
          }

          .clinic-open-hours {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: $lightText;

            strong {
              text-transform: capitalize;
              font-weight: 800;
            }

            span.red {
              color: $colorError;
            }

            .order-1 {
              order: 1;
            }

            .order-2 {
              order: 2;
            }
          }

          .invisible-closed {
            display: none;
          }

          .custom-closed-dates {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .custom-closed-date {
              margin: 0;
              color: $lightText;
            }
          }

          .section-title {
            font-weight: 800;
            margin-bottom: 16px;
          }

          .column-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }

            .section-value {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              color: $lightText;

              .clinic-service {
                color: #0096B7;
                background: #D5F7FF;
                border-radius: 40px;
                padding: 5px 16px;
                font-weight: 800;
                margin-bottom: 8px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .map-container {
        width: calc(30% - 15px);

        @media (max-width: 1023px) {
          width: 100%;
          height: 300px;
          margin-bottom: 16px;
        }

        #map {
          width: 100%;
          height: 100%;
          border-radius: 15px;
          overflow: hidden;
        }
      }
    }
  }
}
