.split001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  &.is-grey {
    background-color: $grey;
  }

  &.with-image {
    padding: 80px 0;

    .split001-container {
      .heading-side {
        .heading-heading {
          margin: 0 0 32px;
        }

        .heading-text {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 170%;
          color: $lightText;
          margin: 0;

          p {
            margin: 0;
          }
        }
      }

      .content-side {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &.is-reverse {
    .split001-container {
      flex-direction: row-reverse;

      @media (max-width: 1023px) {
        flex-direction: column-reverse;
      }

      .heading-side {
        @media (max-width: 1023px) {
          margin-top: 40px;
          margin-bottom: 0;
        }
      }

      .content-side {
        padding-left: 0;
        padding-right: 70px;

        @media (max-width: 1023px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .split001-container {
    @extend .container;
    display: flex;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .heading-side {
      width: 50%;
      @extend h3;

      @media (max-width: 1023px) {
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
      }
    }

    .content-side {
      width: 50%;
      padding-left: 70px;
      color: $lightText;

      @media (max-width: 1023px) {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }

      strong {
        @extend .titleBold;
        color: $dark;
      }
    }
  }
}

.split001-image-container {
  overflow: hidden;
  //@extend .effect-zoom-image;

  .split001-image {
    width: 100%;
    height: 100%;
  }
}
