.arguments004 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;
  padding: 150px 0 70px;

  @media (max-width: 1023px) {
    padding: 80px 0 40px;
  }

  .arguments004-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .arguments004-heading {
      width: 100%;
      max-width: 730px;
      color: $colorAccent1;
      text-align: center;
      margin: 0;

      strong {
        font-weight: 800;
      }
    }

    .arguments004-text {
      width: 100%;
      max-width: 690px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 170%;
      text-align: center;
      color: $light;
      margin: 36px 0 0;
    }

    .arguments004-list {
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 90px;

      @media (max-width: 1023px) {
        margin-top: 64px;
      }

      .argument-item {
        width: calc((100% - 80px) / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;
        margin-right: 40px;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: calc((100% - 40px) / 2);
          margin-bottom: 40px;

          &:nth-child(3n+3) {
            margin-right: 40px;
          }

          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }

        .argument-icon-container {
          width: 120px;
          height: 120px;
          border-radius: 120px;
          background-color: $light;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 32px;

          @media (max-width: 767px) {
            width: 90px;
            height: 90px;
            border-radius: 90px;
            margin-bottom: 16px;
          }

          .argument-icon {
            height: 45px;

            @media (max-width: 767px) {
              height: 32px;
            }
          }
        }

        .argument-name {
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: $light;
          margin: 0;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
