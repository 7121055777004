.arguments002 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .arguments002-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .arguments002-heading {
      width: 100%;
      max-width: 730px;
      color: $colorAccent1;
      text-align: center;
      margin-bottom: 90px;

      @media (max-width: 1023px) {
        margin-bottom: 64px;
      }

      strong {
        font-weight: 800;
      }
    }

    .arguments002-navigation {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      background: $grey;
      border-radius: 50px;
      margin-bottom: 70px;

      @media (max-width: 1023px) {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(100% / 3);
        background-color: $colorAccent2;
        border-radius: 50px;
        transition: transform cubic-bezier(.76,0,.24,1) 0.2s;
      }

      @for $i from 0 through 2 {
        &[index="#{$i}"]:before {
          transform: translateX($i * 100%);
        }
      }

      .arguments002-navigation-link {
        position: relative;
        width: calc(100% / 3);
        color: $lightText;
        font-weight: 800;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        transition: color ease-out 0.2s;

        &.is-active {
          color: $light;
        }
      }
    }

    .arguments002-slider {
      width: 100%;

      .flickity-viewport {
        width: 100%;
      }

      .flickity-page-dots {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }

      .flickity-button {
        display: none;
        width: 44px;
        height: 44px;
        border-radius: 44px;
        background-color: $colorAccent2;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS42MTQgMy41aDguMDg0TDYuMjExLjdsLjYyMi0uNyAzLjU1MyA0LTMuNTUzIDQtLjYyMi0uNyAyLjQ4Ny0yLjhILjYxNHYtMXoiIGZpbGw9IiNGNUY5RkMiLz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-size: 10px 8px;
        background-position: center center;

        @media (max-width: 1023px) {
          display: block;
        }

        &.next {
          right: 0;
          top: -60px;
          transform: none;
        }

        &.previous {
          left: initial;
          right: 60px;
          top: -60px;
          transform: rotate(180deg);
        }

        &:before, & > svg {
          display: none;
        }
      }

      .slider-item {
        width: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
          align-items: flex-start;
          flex-direction: column;
          width: 68%;
          border-radius: 30px;
          overflow: hidden;
          margin-right: 24px;
          box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
          margin-bottom: 50px;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          margin-right: 20px;
          border-radius: 16px;
        }

        .image-side {
          display: flex;
          width: 50%;
          border-radius: 30px;
          overflow: hidden;

          @media (max-width: 1023px) {
            position: relative;
            width: 100%;
            padding-top: 55%;
            overflow: hidden;
            border-radius: 0;
          }

          & > img {
            width: 100%;
            height: 100%;

            @media (max-width: 1023px) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: auto;
            }
          }
        }

        .content-side {
          width: 50%;
          display: flex;
          padding-left: 70px;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 1023px) {
            width: 100%;
            padding: 24px;
          }

          @media (max-width: 767px) {
            width: 100%;
            align-items: center;
            height: 328px;
          }

          .item-name {
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 120%;
            color: $colorAccent1;
            margin: 0 0 12px;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          .item-heading {
            width: 100%;
            @extend .bold;
            margin-bottom: 24px;
          }

          .item-text {
          }

          .item-button.for-desktop {
            margin-top: 50px;
            @extend .button;
            @extend .outlined;

            @media (max-width: 767px) {
              display: none;
            }
          }

          .item-button-container {
            display: none;

            @media (max-width: 767px) {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              height: 100px;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              padding: 0 24px 24px;
              background: linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%);
            }

            .item-button-mobile {
              margin-top: 50px;
              @extend .link-with-arrow;
            }
          }
        }
      }
    }
  }
}
