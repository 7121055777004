.split003 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  &.color-grey {
    background-color: $grey;
  }

  .split003-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      padding: 0;
    }

    .split003-heading-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;

      @media (max-width: 767px) {
        padding: 0 16px;
        margin-bottom: 80px;
      }

      .split003-heading {
        @extend h3;
        text-align: center;
      }

      .split003-text {
        width: 100%;
        max-width: 620px;
        margin-top: 40px;
        color: $lightText;
        text-align: center;
      }
    }

    .split003-lines {
      width: 100%;
      display: flex;
      flex-direction: column;

      .split003-line {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 120px;

        @media (max-width: 1023px) {
          margin-bottom: 80px;
          align-items: flex-start;
        }

        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media (min-width: 768px) {
          &:nth-child(even) {
            flex-direction: row;
          }
        }

        .content-side {
          width: calc(50% - 15px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 1023px) {
            width: calc(60% - 15px);
            padding: 0 16px;
          }

          @media (max-width: 767px) {
            width: 100%;
            align-items: center;
          }

          .content-heading {
            margin-bottom: 24px;
            font-weight: 700;

            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .content-text {
            color: $lightText;

            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .content-button {
            @extend .button;
            @extend .hover-blue;
            @extend .yellow;
            margin-top: 24px;
          }
        }

        .image-side {
          position: relative;
          width: calc(50% - 15px);
          display: flex;
          border-radius: 30px;
          overflow: hidden;
          //@extend .effect-zoom-image;

          @media (max-width: 1023px) {
            width: calc(40% - 15px);
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 40px;
            border-radius: 0;
          }

          img {
            width: 100%;
            height: 100%;
          }

          .content-video {
            width: 100%;
            display: flex;
          }

          .video-info {
            position: absolute;
            left: 24px;
            bottom: 30px;
            display: flex;
            align-items: center;

            & > svg {
              height: 58px;
              margin-right: 16px;
            }

            .video-name {
              color: $grey;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
