.image001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;

  .image001-container {
    width: 100%;
    display: flex;

    &.not-fullscreen {
      @extend .container;
      padding-top: 150px;
      padding-bottom: 150px;

      @media (max-width: 1023px) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }

    .image001-image {
      width: 100%;
    }
  }
}
