.zones001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .zones001-container {
    @extend .container;

    .zones001-heading {
      margin-bottom: 24px;
    }

    .zones001-list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 0;

      .zone-link {
        display: flex;
        align-items: center;

        background: #ced9ea;
        border-radius: 20px;
        width: calc(100% / 5 - ((57px * 4) / 5));
        margin-right: 57px;
        margin-bottom: 52px;
        padding: 8px 16px;
        transition: background ease-out .2s;

        &:nth-child(5n+5) {
          margin-right: 0;
        }

        @media (max-width: 1023px) {
          width: calc(100% / 3 - ((57px * 2) / 3));

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          &:nth-child(5n + 5) {
            margin-right: 57px;
          }
        }

        @media (max-width: 766px) {
          width: calc(100% / 2 - 9px);
          margin-right: 0;
          margin-bottom: 24px;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          &:nth-child(5n + 5) {
            margin-right: 0;
          }

          &:nth-child(odd) {
            margin-right: 17px;
          }
        }

        img {
          display: block;
          max-height: 60px;
          margin-right: 8px;

          @media (max-width: 424px) {
            max-height: 40px;
            margin-right: 0;
          }

          &.hover {
            display: none;
          }
        }

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 170%;
          color: $colorAccent1;
          margin-top: 5px;
          margin-bottom: 0;
          transition: color ease-out .2s;
        }

        &:hover {
          background: #26C5EC;

          img {
            &.active {
              display: none;
            }
            &.hover {
              display: block;
            }
          }

          span {
            color: $light;
          }
        }
      }
    }
  }
}
