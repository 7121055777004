.blog003 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  &.for-landing {
    background-color: $grey;

    .blog003-container {
      .blog003-heading {
        color: $colorAccent2;
      }
    }
  }

  .blog003-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blog003-heading {
      color: $colorAccent1;
      margin-bottom: 65px;
      text-align: center;

      strong {
        font-weight: 800;
      }
    }

    .blog003-listing {
      width: 100%;
      display: flex;
      margin-bottom: 55px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .blog-item {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        display: flex;
        flex-direction: column;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .blog-image-container {
          border-radius: 30px;
          margin-bottom: 35px;
          @extend .effect-zoom-image;

          @media (max-width: 767px) {
            border-radius: 16px;
            width: 110px;
            height: 110px;
            margin-bottom: 0;
          }

          .blog-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .blog-infos {
          width: 100%;
          display: flex;
          flex-direction: column;

          @media (max-width: 767px) {
            width: calc(100% - 126px);
          }
        }

        .blog-category {
          @extend .subtitle;
          color: $lightText;
          margin-bottom: 8px;
        }

        .blog-title {
          @extend .titleBold;
          margin-bottom: 10px;
        }

        .blog-short {
          color: $lightText;

          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .blog003-button {
      @extend .button;
      @extend .outlined;
    }
  }
}
