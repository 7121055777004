.custom-popup-banner {
  position: relative;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: #E0F2FC;
  padding: 16px 90px 16px 30px;

  &.is-active {
    display: flex;
  }

  @media (max-width: 767px) {
    padding: 60px 30px 24px;
    flex-direction: column;
  }

  .banner-text {
    @extend .titleBold;
    text-align: center;
  }

  .banner-button {
    white-space: nowrap;
    margin-left: 24px;
    @extend .button;
    @extend .outlined;
    @extend .custom;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  .banner-close {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 767px) {
      top: 16px;
      right: 16px;
      transform: none;
    }

    svg {
      width: 16px;
    }
  }
}

.custom-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(#0D313F, 0.4);
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-out .2s;

  &.is-active {
    pointer-events: auto;
    opacity: 1;

    .custom-popup-wrapper {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .custom-popup-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .custom-popup-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 50px));
    width: calc(100% - 64px);
    max-width: 800px;
    max-height: calc(100vh - 64px);
    overflow-y: scroll;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 29.2013px rgba(0, 0, 0, 0.0482987), 0px 0px 14.1767px rgba(0, 0, 0, 0.0389404), 0px 0px 6.94968px rgba(0, 0, 0, 0.0310596), 0px 0px 2.74791px rgba(0, 0, 0, 0.0217013);
    border-radius: 16px;
    background: #FFFFFF;
    opacity: 0;
    transition: opacity ease-out .2s, transform ease-out .2s;
    transition-delay: .2s;

    .custom-popup-container {
      position: relative;
      width: 100%;
      display: flex;
      align-items: stretch;

      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .custom-popup-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 50px 50px 40px;

        @media (max-width: 767px) {
          padding: 30px 24px;
        }

        .custom-popup-title {
          @extend .titleBold;
          margin-bottom: 24px;
        }

        .custom-popup-text {
          color: $lightText;
        }

        .custom-popup-button {
          margin-top: 24px;
          @extend .button;
          @extend .outlined;
        }
      }

      .custom-popup-image {
        display: flex;
        width: 100%;
        max-width: 256px;

        @media (max-width: 767px) {
          max-width: none;
          height: 150px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }



      .custom-popup-close {
        position: absolute;
        top: 16px;
        right: 16px;
        transform: none;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 16px;
        }
      }
    }
  }
}
