.hero002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;

  &.color-blue {
    background-color: $colorAccent1;
  }

  &.color-grey {
    background-color: $lightText;
  }

  .hero002-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      align-items: flex-start;
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .content-side {
      width: calc(50% - 45px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        width: calc(70% - 20px);
      }

      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
      }

      .hero002-heading {
        color: $light;
        font-weight: 800;
        margin-bottom: 40px;

        @media (max-width: 767px) {
          text-align: center;
          margin-bottom: 20px;
        }
      }

      .hero002-text {
        color: $light;
        max-width: 490px;

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .buttons-line {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 60px;

        @media (max-width: 767px) {
          margin-top: 30px;
          flex-direction: column;
          align-items: center;
        }

        .hero002-button {
          @extend .button;
          @extend .yellow;
          margin-right: 30px;
          margin-bottom: 30px;

          @media (max-width: 1023px) {
            margin-right: 20px;
            margin-bottom: 20px;
          }

          @media (max-width: 767px) {
            display: none;
          }
        }

        .hero002-link {
          display: flex;
          align-items: center;
          color: $light;
          font-weight: 800;
          margin-bottom: 30px;

          &:before {
            content: '';
            display: block;
            margin-right: 4px;
            width: 28px;
            height: 28px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyAyNyI+PHBhdGggZD0iTTQuNSAxMy41djcuODc1aDE4VjEzLjVNMTMuNSAzLjkzOHYxMS4yNU0xMy41IDE1LjE4OGwzLjc5Ny0zLjc5N00xMy41IDE1LjE4OGwtMy43OTctMy43OTciIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
        }
      }
    }

    .image-side {
      width: calc(50% - 45px);
      height: auto;
      display: flex;

      @media (max-width: 1023px) {
        width: calc(30% - 20px);
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 230px;
        margin-bottom: 30px;
      }

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
