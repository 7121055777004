.appointment-outro {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $grey;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 20px 0;
  }

  .appointment-outro-container {
    @extend .container;
    max-width: calc(950px + (2 * 30px));
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .content-side {
      width: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 1023px) {
        width: 100%;
        align-items: center;
      }

      .content-heading {
        font-weight: 800;
        margin-bottom: 30px;
        text-align: center;
      }

      .content-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: $light;
        border-radius: 32px;
        margin-bottom: 24px;
      }

      .content-text {
        width: 100%;
        text-align: center;

        strong {
          font-weight: 800;
        }

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          list-style: none;

          li {
            font-weight: 800;
            color: $colorAccent1;
            text-align: center;

            &:before {
              content: '• ';
            }
          }
        }
      }

      .dropzone-wrapper {
        width: 100%;
        border: dashed $colorAccent1 2px;
        border-radius: 32px;
        background-color: $light;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0 48px;
      }

      .dropzone {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dropzone-label {
        width: 100%;
        height: 100px;
        text-align: center;
        color: $colorAccent1;
        font-size: 24px;
        font-weight: bold;
        line-height: 100px;

        &:hover {
          cursor: pointer;
        }
      }

      .file-list-wrapper {
        padding: 25px 56px;
        width: 100%;
      }

      .file-list {
        width: 100%;
        list-style: none;
        margin: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .file-item {
        width: 25%;
        height: 63px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      .file-item-img-container {
        position: relative;
        width: 100%;
        height: 100%;
        border: solid #a19b9b 1px;
        border-radius: 16px;

        &:hover {
          cursor: pointer;


          .file-item-delete-btn {
            cursor: pointer;
            opacity: 1;
            visibility: visible;
            backdrop-filter: blur(6px);
            background-color: transparent;
          }
        }
      }

      .file-item-pdf {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .file-item-pdf-icon {
        width: 25px;
        height: 25px;
      }

      .file-item-img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
        transition: backdrop-filter .3s ease-in-out;
      }

      .file-item-delete-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        opacity: 0;
        border-radius: 16px;
        visibility: hidden;
        backdrop-filter: blur(0);
        transition: all .3s ease-in-out;
      }

      .file-item-delete-btn-icon {
        width: 24px;
        height: 24px;
      }

      .file-label {
        width: 100%;
        background-color: $colorAccent3;
        border-radius: 26px;
        color: $dark;
        padding: 11px 0;
        font-size: 20px;
        margin-top: 12px;
        display: block;
        text-align: center;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }

      .success-message {
        color: green;
        margin-bottom: 24px;
      }

      .content-button-light {
        @extend .button;
        @extend .outlined;
        width: 100%;
        max-width: 330px;
        margin-bottom: 12px;
      }

      .content-button {
        @extend .button;
        @extend .blue;
        width: 100%;
        max-width: 330px;
      }
    }

    .image-side {
      width: calc(100% - 450px - 90px);
      display: flex;

      @media (max-width: 1023px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
