// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontNexa: 'Nexa', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #00BCE7;
$colorAccent2: #004053;
$colorAccent3: #FFCB07;

$lightText: #73879A;

// Texts
$dark: #0D313F;
$grey: #F4F8FF;
$light: #FFFFFF;

$colorSuccess: #00966D;
$colorError: #FF564A;
