.creations-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;
  padding: 50px 0;

  @media (max-width: 550px) {
    padding: 90px 0;
  }

  .footer-container {
    @extend .container;
    display: flex;
    align-items: stretch;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .footer-column {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 767px) {
        margin-bottom: 32px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        align-items: center;
      }

      &:nth-child(3) {
        align-items: flex-end;

        @media (max-width: 767px) {
          align-items: center;
        }
      }

      .logo {
        display: flex;
        margin-bottom: 40px;

        & > svg {
          height: 50px;
          max-width: 100%;
        }
      }

      .info-button {
        @extend .button;

        @media (max-width: 550px) {
          display: none;
        }
      }

      .footer-infos {
        display: flex;
        flex-direction: column;

        @media (max-width: 550px) {
          align-items: center;
          width: 100%;
          padding: 30px 0;
          border-top: 1px solid rgba(#8298AB, 0.35);
          border-bottom: 1px solid rgba(#8298AB, 0.35);
        }

        .info-link {
          display: flex;
          align-items: center;
          color: $light;
          margin-bottom: 10px;

          & > div {
            width: 22px;
            display: flex;
            justify-content: center;
            margin-right: 10px;

            & > svg {
              height: 17px;
            }
          }
        }
      }

      .social-links {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        @media (max-width: 550px) {
          margin: 40px 0;
        }

        .nav-link {
          position: relative;
          width: 42px;
          height: 42px;
          border-radius: 42px;
          background-color: $light;
          margin-right: 10px;
          transition: all ease-out 0.2s;
          // Yellow
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI0ZGQ0IwNyIvPjwvc3ZnPg==");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 0;

          &:hover {
            background-size: 100%;
          }

          &:last-child {
            margin-right: 0;
          }

          & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 17px;
          }
        }
      }

      .footer-newsletter {
        display: flex;
        flex-direction: column;

        @media (max-width: 550px) {
          width: 100%;
          margin-top: 40px;
          align-items: center;
        }

        .newsletter-label {
          color: $light;
          font-weight: 700;
          margin-bottom: 20px;
          text-align: center;
        }

        .info-button {
          @extend .button;
        }

        .input-container {
          width: 100%;
          max-width: 230px;
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          background-color: rgba(#E1E9F0, 0.4);
          border-radius: 50px;
          overflow: hidden;

          .newsletter-input {
            flex: 1;
            appearance: none;
            outline: none;
            border: none;
            @extend .caption;
            padding: 15px 0 15px 15px;
            color: $light;
            background-color: transparent;
          }

          button[type="submit"] {
            width: 55px;
            background-color: transparent;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy45MDguMDYzYS4yODMuMjgzIDAgMDAtLjI5LS4wNEwuMTU2IDcuNjQ4QS4yNTMuMjUzIDAgMDAwIDcuODc0YS4yNTIuMjUyIDAgMDAuMTUuMjNMMy42IDkuNTY1Yy4wOS4wNDIgNy4yLTMuNDc4IDcuMi0zLjQ3OEw2LjQzNyAxMC42OGEuNS41IDAgMDAtLjEzNy4zNDR2My40MDZhLjUuNSAwIDAwLjg1Ny4zNWwyLjI4NC0yLjMyNyAzLjYwMSAxLjcxM2MuMDcuMDMxLjE1Mi4wMzMuMjIzLjAwM2EuMjU5LjI1OSAwIDAwLjE1LS4xNTZMMTcuOTg4LjMzYy4wMzItLjA5NiAwLS4yLS4wOC0uMjY2eiIgZmlsbD0iI0Y1RjlGQyIvPjwvc3ZnPg==");
            background-size: 18px 15px;
            background-repeat: no-repeat;
            appearance: none;
            outline: none;
            background-position: top 15px right 20px;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
