.qhv-form-container {
  @extend .container;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: calc(900px +(2* 30px));

  .form-select {
    width: 100%;
    border-radius: 15px;
    padding: 20px 45px 20px 24px;
    font-weight: bold;
    border: none;
    appearance: none;
    outline: none;
    background-color: $grey;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA0Ij48cGF0aCBkPSJNOC41IDEuMjVMNS4wMTggMi45OSAxLjUzNyAxLjI1IiBzdHJva2U9IiM3Mzg3OUEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+");
    background-size: 7px 7px;
    background-repeat: no-repeat;
    background-position: center right 24px;
  }
  
  .form-input {
    width: 100%;
    border-radius: 15px;
    padding: 20px 45px 20px 24px;
    font-weight: bold;
    border: none;
    appearance: none;
    outline: none;
    background-color: $grey;
  }

  .form-textarea {
    width: calc(100% - 190px);
    border-radius: 15px;
    padding: 20px 45px 20px 24px;
    font-weight: bold;
    border: none;
    appearance: none;
    outline: none;
    background-color: $grey;
    min-height: 150px;

    @media (max-width: 767px) {
        width: 100%;
    }
  }

  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .qhv-form-title {
        @extend h3;
        font-weight: 800;
    }

    .qhv-form-info {
        width: 100%;
        background-color: $grey;
        padding: 30px;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        gap: 30px;

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 20px;
            padding: 20px;
        }

        svg {
            height: 50px;
            width: 50px;
        }

        .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;


        }
    }

    .patent-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
  }

  .form-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .section-heading {
        @extend h4;
    }

    .section-subheading {
        @extend .titleBold;
        margin-top: 20px;
        margin-bottom: -20px;
    }

    .section-description {
        @extend .regularText;
        width: calc(100% - 190px);

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .section-more-link {
        @extend .regularText;
        @extend .bold;
        color: $colorAccent1;
        cursor: pointer;
    }

    .section-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .form-line {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px 20px;
            padding: 10px 0;
            border-bottom: 1px solid rgba($colorAccent2, 0.2);

            &:has(.form-error:not(.is-hidden)) {
                flex-wrap: wrap;
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }

            &:has(.form-textarea) {
                flex-direction: column;
                align-items: flex-start;

                .form-answer-section {
                    width: 100%;
                }
            }

            .form-label {
                @extend .regularText;
                display: flex;
                align-items: center;
                gap: 10px;

                .form-select {
                    width: 140px;
                }
            }

            .form-answer-section {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .form-select {
                    width: 125px;
                }

                .form-input {
                    width: 125px;

                    &.longer {
                        width: 250px;
                    }
                }

                &.mini {
                    width: 90px;
                }
            }

            .form-error {
                width: 100%;
                color: $colorError;
                font-weight: bold;

                &.is-hidden {
                    display: none;
                }
            }
        }
    }
  }
}
