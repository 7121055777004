.newsletter001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $grey;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .newsletter001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .newsletter001-heading {
      @extend h3;
      text-align: center;
      margin-bottom: 30px;

      strong {
        font-weight: 800;
      }
    }

    .newsletter001-text {
      color: $lightText;
      text-align: center;
      width: 100%;
      max-width: 570px;
      margin-bottom: 30px;
    }

    .newsletter001-message {
      width: 100%;
      max-width: 555px;
      padding: 10px 30px;
      text-align: center;
      border-radius: 8px;
      margin-bottom: 30px;

      &.success {
        background-color: rgba($colorSuccess, .2);
      }

      &.error {
        background-color: rgba($colorError, .2);
      }
    }

    .newsletter001-form {
      width: 100%;
      display: flex;
      justify-content: center;

      .newsletter001-button {
        @extend .button;
        @extend .yellow;
      }

      .input-container {
        width: 100%;
        max-width: 230px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        background-color: rgba($colorAccent2, 0.4);
        border-radius: 50px;
        overflow: hidden;

        .newsletter-input {
          flex: 1;
          appearance: none;
          outline: none;
          border: none;
          @extend .caption;
          padding: 15px 0 15px 15px;
          color: $light;
          background-color: transparent;
        }

        ::placeholder {
          color: $light;
        }

        button[type="submit"] {
          width: 55px;
          background-color: transparent;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy45MDguMDYzYS4yODMuMjgzIDAgMDAtLjI5LS4wNEwuMTU2IDcuNjQ4QS4yNTMuMjUzIDAgMDAwIDcuODc0YS4yNTIuMjUyIDAgMDAuMTUuMjNMMy42IDkuNTY1Yy4wOS4wNDIgNy4yLTMuNDc4IDcuMi0zLjQ3OEw2LjQzNyAxMC42OGEuNS41IDAgMDAtLjEzNy4zNDR2My40MDZhLjUuNSAwIDAwLjg1Ny4zNWwyLjI4NC0yLjMyNyAzLjYwMSAxLjcxM2MuMDcuMDMxLjE1Mi4wMzMuMjIzLjAwM2EuMjU5LjI1OSAwIDAwLjE1LS4xNTZMMTcuOTg4LjMzYy4wMzItLjA5NiAwLS4yLS4wOC0uMjY2eiIgZmlsbD0iI0Y1RjlGQyIvPjwvc3ZnPg==");
          background-size: 18px 15px;
          background-repeat: no-repeat;
          appearance: none;
          outline: none;
          background-position: top 15px right 20px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
