.cta001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $colorAccent1;

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  .cta001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cta-heading {
      @extend h3;
      color: $light;
      text-align: center;
      margin-bottom: 30px;

      strong {
        font-weight: 900;
      }
    }

    .cta-button {
      @extend .button;
    }
  }
}
