.content-pagination {
  width: 100%;
  display: flex;

  .desktop-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      display: none;
    }

    .content-pagination-prev, .content-pagination-next {
      position: relative;
      width: 44px;
      height: 44px;
      border-radius: 44px;
      background-color: $colorAccent2;
      margin: 0 20px;

      &.is-disabled {
        opacity: .3;
      }

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
      }
    }

    .page-link {
      @extend .caption;
      width: 24px;
      text-align: center;
      margin: 0 4px;
    }

    .is-current {
      @extend .caption;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 24px;
      background-color: $colorAccent2;
      text-align: center;
      color: $light;
      font-weight: 800;
      margin: 0 4px;
    }
  }

  .mobile-pagination {
    display: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: flex;
    }

    .pagination-side {
      a {
        @extend .link-with-arrow;
      }

      &:first-child > a {
        &:after {
          display: none;
        }

        &:before {
          content: '';
          display: block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          transform: rotate(180deg);
        }
      }
    }
  }
}
