.map001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $light;

  @media (max-width: 767px) {
    padding: 80px 0;
  }

  &.for-landing {
    background-color: $grey;

    .map001-container {
      .map001-heading {
        width: 100%;

        p {
          text-align: center;
        }
      }

      .postal-code-form .form-line {
        &:before, .form-input {
          background: $light;
        }
      }

      .clinic-item {
        background: $light;
        border-color: $light;
      }
    }
  }

  &.light {
    background-color: $grey;

    .map001-container {
      .map001-heading {
        width: 100%;
      }

      .postal-code-form .form-line {
        &:before, .form-input {
          background: $light;
        }
      }

      .clinic-item {
        background: $light;
        border-color: $light;
      }
    }
  }

  .map001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .map001-heading {
      @extend h3;
      margin-bottom: 60px;

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .map001-filters-bar {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 40px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .map001-filters-button-container {
        display: flex;

        @media (max-width: 767px) {
          margin-top: 8px;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .map001-filters-button {
          @extend .button;
          @extend .outlined;

          @media (max-width: 767px) {
            width: 100%;
          }

          svg {
            height: 20px;
            margin-right: 10px;
          }

          &:hover {
            svg {
              path {
                fill: $light;
              }
            }
          }
        }
      }
    }

    .map001-filters-wrapper {
      display: none;
      position: fixed;
      inset: 0;
      z-index: 50;

      &.is-active {
        display: block;
      }

      .filters-overlay {
        position: absolute;
        inset: 0;
        background: $dark;
        opacity: 0.4;
      }

      .filters-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: calc(100% - 32px);
        max-width: 736px;
        max-height: calc(100vh - 32px);
        overflow-y: scroll;
        background: $light;
        border-radius: 16px;
        padding: 100px 100px 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1023px) {
          padding: 60px;
          max-width: 500px;
        }

        @media (max-width: 767px) {
          padding: 80px 30px 50px;
          max-width: 500px;
        }

        .filters-close {
          content: '';
          position: absolute;
          top: 14px;
          right: 14px;
          width: 38px;
          height: 38px;
          cursor: pointer;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PHBhdGggZD0iTTE3LjQuNjEzYTEuMzI4IDEuMzI4IDAgMCAwLTEuODggMEw5IDcuMTIgMi40OC42QTEuMzI4IDEuMzI4IDAgMCAwIC42LjZjLS41Mi41Mi0uNTIgMS4zNiAwIDEuODhMNy4xMiA5IC42IDE1LjUyYy0uNTIuNTItLjUyIDEuMzYgMCAxLjg4LjUyLjUyIDEuMzYuNTIgMS44OCAwTDkgMTAuODhsNi41MiA2LjUyYy41Mi41MiAxLjM2LjUyIDEuODggMCAuNTItLjUyLjUyLTEuMzYgMC0xLjg4TDEwLjg4IDlsNi41Mi02LjUyYTEuMzM2IDEuMzM2IDAgMCAwIDAtMS44NjdaIiBmaWxsPSIjNzM4NzlBIi8+PC9zdmc+");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 18px;
        }

        .filters-header {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 40px;

          .filters-heading {
            font-family: $fontNexa;
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 120%;
            color: $dark;
            margin: 0;
          }

          .filter-reset {
            font-family: $fontNexa;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 170%;
            color: $colorAccent1;
            margin: 0;

            &.is-inactive {
              opacity: .5;
            }
          }
        }

        .filters-list {
          width: 100%;
          display: flex;
          flex-direction: column;

          .filter-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
            cursor: pointer;

            .filter-name {
              font-family: $fontNexa;
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 135%;
              color: $dark;
              margin: 0;
            }

            .filter-input {
              display: none;

              &:checked + .filter-lightbox {
                background-color: rgba(#00BCE7, .3);

                &:before {
                  background-color: $colorAccent1;
                  transform: translateX(24px);
                }
              }
            }

            .filter-lightbox {
              position: relative;
              width: 48px;
              height: 24px;
              border-radius: 24px;
              background-color: rgba(#73879A, .3);
              transition: all ease-out .2s;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 24px;
                height: 24px;
                border-radius: 24px;
                background-color: #73879A;
                transition: all ease-out .2s;
              }
            }
          }
        }

        .filter-submit {
          flex-shrink: 0;
          @extend .button;
          @extend .blue;
          margin-top: 20px;
          border-style: solid;
        }
      }
    }
  }
}

.postal-code-form {
  width: calc(50% - 15px);
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    width: 100%;
  }

  .form-label {
    width: 100%;
    font-weight: 800;
    margin-bottom: 8px;
  }

  .form-line {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50px;
      right: 50px;
      top: 0;
      bottom: 0;
      background-color: $grey;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .form-input {
      position: relative;
      background: $grey;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      padding: 15px 24px;
      border: none;
      appearance: none;
      outline: none;
      flex: 1;

      @media (max-width: 767px) {
        width: 100%;
        border-radius: 15px;
        margin-bottom: 16px;
      }

      &::placeholder {
        color: $lightText;
      }
    }

    .form-button {
      position: relative;
      color: $light;
      font-weight: 800;
      text-align: center;
      background-color: $colorAccent1;
      border-radius: 50px;
      padding: 15px 24px;
      border: none;
      appearance: none;
      outline: none;
      cursor: pointer;
      transition: all ease-out 0.2s;
      // Yellow
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI0ZGQ0IwNyIvPjwvc3ZnPg==");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 0;

      @media (max-width: 767px) {
        width: 100%;
      }

      span, &:after {
        transition: opacity ease-out .2s;
      }

      &:not(.is-loading):hover {
        background-size: 110%;
      }

      &.is-loading {
        span {
          opacity: 0;
        }

        &:after {
          opacity: 1;
          content: '';
          position: absolute;
          width: 30px;
          height: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0cm9rZT0iI2ZmZiIgdmlld0JveD0iMCAwIDM4IDM4Ij48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgMTggMTgiIHRvPSIzNjAgMTggMTgiIGR1cj0iMXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9wYXRoPjwvZz48L3N2Zz4=");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;

          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .errors {
    color: $colorError;
    padding: 0 0 16px;

    &.is-hidden {
      display: none;
    }

    p {
      font-size: 12px;
    }
  }
}

.map001, .form-process {
  .map-list-container {
    width: 100%;
    height: 530px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    //margin-top: 40px;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
      height: auto;
    }

    .list-container {
      position: relative;
      width: calc(50% - 15px);
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      padding-right: 5px;

      @media (max-width: 1023px) {
        width: 100%;
        height: auto;
        padding-right: 0;
      }

      /* Let's get this party started */
      &::-webkit-scrollbar {
        width: 5px;

        @media (max-width: 1023px) {
          display: none;
        }
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(#73879A, .2);
        border-radius: 10px;
        overflow: hidden;
      }

      .postal-code-form {
        width: 100%;
        margin-bottom: 20px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;

        .form-button {
          margin: 0;
        }
      }

      .clinics-list-container {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;

        .clinics-list {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .show-more-clinics {
          font-family: $fontNexa;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 170%;
          color: $colorAccent1;
          display: flex;
          align-items: center;
          text-decoration: none;

          & > svg {
            height: 24px;
          }
        }
      }

      .clinic-item {
        cursor: pointer;
        min-height: auto;
      }
    }

    .map-container {
      width: calc(50% - 15px);

      @media (max-width: 1023px) {
        width: 100%;
        height: 300px;
        margin-bottom: 16px;
      }

      #map {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
      }
    }
  }
}
