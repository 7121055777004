.split002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $grey;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px;
  }

  .split002-container {
    @extend .container;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      align-items: flex-start;
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      padding: 0;
    }

    .content-side {
      width: 50%;
      padding-right: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        width: 60%;
        padding-right: 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
        padding-right: 0;
        padding: 0 16px;
      }

      .split002-heading {
        @extend h3;
        margin-bottom: 30px;

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .split002-text {
        color: $lightText;

        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }

    .image-side {
      width: 50%;
      display: flex;
      border-radius: 30px;
      overflow: hidden;
      //@extend .effect-zoom-image;

      @media (max-width: 1023px) {
        width: 40%;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 40px;
        border-radius: 0;
      }

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
