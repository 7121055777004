.clinic-item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 16px 100px 16px 16px;
  background-color: $grey;
  margin-bottom: 16px;
  border: 2px solid $grey;
  transition: border-color ease-out .2s;
  min-height: 125px;

  @media (max-width: 767px) {
    padding: 16px 90px 50px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: flex;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
    background-position: center left 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-position ease-out .2s;
  }

  &:hover {
    &:after {
      animation: arrow-right 1s ease-out infinite;
    }
  }

  &.is-active {
    border-color: $colorAccent1;
  }

  &.without-arrow {
    display: none;

    &:after {
      display: none;
    }

    &.is-visible {
      display: flex;
    }
  }

  //&.is-hidden {
  //  display: none;
  //}

  &.is-really-hidden {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .clinic-name {
    font-weight: 800;
    margin-bottom: 8px;
  }

  .clinic-address {
    color: $lightText;
  }

  .clinic-button {
    @extend .button;
    @extend .blue;
    @extend .small;
    border-color: $colorAccent1;
    appearance: none;
    width: 100%;
    margin-top: 16px;
  }

  .clinic-distance {
    display: none;
    position: absolute;
    top: 16px;
    right: 16px;
    font-weight: 800;
    color: #0096B7;
    background: #D5F7FF;
    border-radius: 40px;
    padding: 5px 16px;

    &.is-active {
      display: block;
    }
  }
}
