.appointment-intro-fullwidth {
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: $grey;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .appointment-intro-container {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    padding: 150px 30px;

    @media (max-width: 1023px) {
      width: 100%;
      padding: 40px 30px;
    }

    .content-side {
      width: 100%;
      max-width: calc(100% - ((100vw - (950px + (2 * 30px))) / 2));
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        width: 100%;
        align-items: center;
      }

      .content-heading {
        font-weight: 800;
        margin-bottom: 30px;

        @media (max-width: 1023px) {
          text-align: center;
        }
      }

      .content-text {
        color: $lightText;
        margin-bottom: 30px;

        @media (max-width: 1023px) {
          text-align: center;
        }
      }

      .content-strong {
        @extend .titleBold;
        color: $colorAccent1;
        margin-bottom: 30px;

        @media (max-width: 1023px) {
          text-align: center;
        }
      }

      .content-info {
        width: 100%;
        padding: 10px;
        margin: 0 0 30px;
        border-radius: 4px;
        background-color: rgba($colorError, .1);
        border: 1px solid $colorError;
        color: $colorError;
        font-size: 14px;
        font-style: italic;
      }

      .content-button {
        @extend .button;
        @extend .blue;
      }

      .content-cell-section {
        margin-top: 32px;
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
        background: $light;
        border-radius: 16px;
        padding: 24px 30px;

        @media (max-width: 767px) {
          display: flex;
        }

        .cell-text {
          @extend .titleBold;
          color: $colorAccent1;
          margin-bottom: 16px;
          text-align: center;
        }

        .cell-button {
          @extend .button;
          @extend .outlined;

          & > svg {
            height: 18px;
            margin-right: 8px;

            & > path {
              transition: fill ease-out .2s;
            }
          }

          &:hover > svg > path {
            fill: $light;
          }
        }
      }

      .content-form {
        width: 100%;
        max-width: 380px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .errors {
          width: 100%;
          border-radius: 8px;
          background-color: $colorError;
          color: $light;
          display: flex;
          flex-direction: column;
          padding: 8px 16px;
          margin-bottom: 32px;

          p {
            width: 100%;
            margin: 0 0 8px;
            font-weight: 600;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &.for-input {
            margin: -16px 0 0;
            background-color: transparent;
            color: $colorError;
            padding: 0 0 16px;

            &.is-hidden {
              display: none;
            }

            p {
              font-size: 12px;
            }
          }
        }

        .message {
          width: 100%;
          border-radius: 8px;
          background-color: $colorSuccess;
          color: $light;
          display: flex;
          flex-direction: column;
          padding: 8px 16px;
          font-weight: 600;
          margin-bottom: 32px;

          &.error {
            background-color: $colorError;
          }
        }

        .input-wrapper {
          @extend .light;
          margin-bottom: 24px;
        }

        .form-button-apnea {
          @extend .button;
          @extend .blue;
          margin-top: 8px;
          border: none;
          width: auto;
          padding-left: 75px;
          padding-right: 75px;
          outline: none;

          @media (max-width: 767px) {
            width: 100%;
            padding-left: inherit;
            padding-right: inherit;
          }
        }
      }
    }
  }

  .image-side {
    width: 45%;
    display: flex;
    background-position: center center;
    background-size: cover;

    @media (max-width: 1023px) {
      width: 100%;
      height: 250px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}
