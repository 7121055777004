.hero004 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //min-height: 600px;
  background-color: $grey;

  @media (max-width: 1150px) {
    min-height: 450px;
  }

  .hero004-breadcrumb {
    @extend .container;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 31px;

    @media (max-width: 1023px) {
      justify-content: flex-start;
    }

    .breadcrumb-link {
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
      color: #293F52;
      margin: 3px 12px 3px 0;

      &:last-child {
        margin-right: 0;
      }

      &.is-current {
        color: $colorAccent1;
      }
    }

    svg {
      margin-right: 12px;
    }
  }

  .hero004-container {
    @extend .container;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 80px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .hero004-image-container {
      width: 320px;
      height: 194px;
      padding: 8px;
      background-color: #27c5ec;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;

      @media (max-width: 1023px) {
        margin-right: 0;
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      .hero004-image {
        max-height: 100%;
      }
    }

    .content-side {
      width: calc(100% - 320px - 40px);

      @media (max-width: 1023px) {
        width: 100%;
      }

      .hero004-heading {
        @extend h3;
        width: 100%;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;


        @media (max-width: 1023px) {
          width: 100%;
          text-align: left;
        }

        strong {
          font-weight: 800;
        }
      }

      .hero004-text {
        width: 100%;
        color: $lightText;

        @media (max-width: 1023px) {
          width: 100%;
          text-align: left;
        }
      }
    }


  }

  .image-side {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-position: center center;
    background-size: cover;

    @media (max-width: 1023px) {
      display: none;
    }
  }
}
