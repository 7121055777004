.career001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  background-color: $grey;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  .career001-container {
    position: relative;
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 90px;
    padding-bottom: 90px;

    @media (max-width: 1023px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .career001-heading {
      width: 100%;
      max-width: calc(50% - 100px);
      @extend h3;
      margin-bottom: 30px;

      strong {
        font-weight: 800;
      }

      @media (max-width: 1023px) {
        max-width: 100%;
        text-align: center;
      }
    }

    .career001-text {
      width: 100%;
      max-width: calc(50% - 100px);
      color: $lightText;
      margin-bottom: 60px;

      @media (max-width: 1023px) {
        max-width: 100%;
        text-align: center;
      }
    }

    .career001-list {
      position: relative;
      z-index: 2;
      width: 100%;
      display: flex;
      margin-bottom: 60px;

      @media (max-width: 1023px) {
        padding-bottom: 50px;
      }

      .flickity-viewport {
        width: 100%;
      }

      .flickity-page-dots {
        display: none;

        @media (max-width: 1023px) {
          display: block;
        }
      }

      &:after {
        content: '';
      }

      @media (max-width: 1023px) {
        &:after {
          content: 'flickity';
          display: none; /* hide :after */
        }
      }

      .job-item {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        background-color: $light;
        border-radius: 20px;
        padding: 20px;
        min-height: 155px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 8px 30px rgba(0, 64, 83, 0.1);

        @media (max-width: 1023px) {
          width: 70vw;
          max-width: 255px;
          box-shadow: none;
        }

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        .job-line {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .job-tag {
            @extend .caption;
            color: $light;
            font-weight: 800;
            padding: 5px 10px;
            background-color: $colorAccent1;
            border-radius: 16px;
            margin-right: 10px;
            margin-bottom: 10px;
          }

          .job-date {
            @extend .caption;
            color: $lightText;
            margin-bottom: 10px;
          }

          .job-title {
            width: 100%;
            font-weight: 800;
          }

          .job-place {
            color: $lightText;
            text-transform: uppercase;
          }
        }
      }
    }

    .career001-link {
      @extend .link-with-arrow;
    }

    .career001-image {
      position: absolute;
      top: 0;
      display: flex;
      right: 90px;
      width: calc(50% - 90px);
      border-radius: 30px;
      overflow: hidden;
      @extend .effect-zoom-image;

      @media (max-width: 1023px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
